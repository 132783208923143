import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { Context } from '../../Store/store';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import FlashMessage from '../FlashMessage/index';
import ApiRoutes from '../../Services/ApiRoutes';


export default function VerificationCode({ handleResend,submitForm }){
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    const [code,setCode] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        let params = new FormData(form);
        params.append("type","registration")
        submitForm(params);
    }

    // const handleResend = () => {
    //     dispatch({type: "SET_STATE", response: [], flash_message_show: false});
    //     overlay(true);
    //
    //     const params = new FormData();
    //     params.append("type",type);
    //     params.append("mobile_code",mobile_code);
    //     params.append("mobile_no",mobile_no);
    //     params.append("forget_token",forget_token);
    //     params.append("email",email);
    //     params.append("code_resend",1)
    //     HttpRequest.makeRequest(
    //         ApiRoutes.loginGenerateFPCode.method,
    //         ApiRoutes.loginGenerateFPCode.end_point,
    //         params)
    //         .then((response) => {
    //             if(response.resCode === 200){
    //                 overlay(false);
    //                 dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
    //                 setTimeout(()=>{history.push({
    //                     pathname:'/na-verification-code',
    //                     state: response.data
    //                 })},2000);
    //             }else{
    //                 dispatch({type:'SET_STATE', response: response, flash_message_show: true})
    //                 overlay(false);
    //             }
    //         }).catch(err=>{
    //         devLog("error",err);
    //     });
    // };

    const handleChangeNumber = () => {
        history.push("/change-phone-number-login"); 
    };

    return(
        <>
            <Helmet>
                <title>Consent - Verification Code</title>
            </Helmet>
            <div className="rg-maincontainer">

                <main className="rg-form">
                    <form onSubmit={handleSubmit}>
                        <Link to="/home">
                            <img 
                                className="login-header-img fp-header-img login-header-modified" 
                                src="./assets/images/newlogo.png"
                                alt=" Main Logo " 
                                width="72" 
                                height="57"
                            />
                        </Link>
                        <FlashMessage />
                        <h1 className="rg-form-heading fp-specialfont">Verification Code</h1>
                        <p className="rg-form-para">We sent a six digit verification code to your email.</p>

                        <div className="rg-form-floating">
                            <label htmlFor="floatingInput">Please enter the code to proceed.</label>
                            <input
                                type="text"
                                className="rg-formcontrol rg-mbpass"
                                id="floatingInput"
                                onChange={(evt) => setCode(evt.target.value)}
                                name="code"
                            />
                            <p className="rc-resendcode ">Didn’t get the Code? <button className="rg-formbutton"
                                                                                       type="button" onClick={() => {
                                handleResend()
                            }}> Resend Code</button></p>
                            {/* <p className="rc-resendcode mb-0">Didn’t get the Code? <a  href="#" onClick={handleResend}> Resend Code</a> </p> */}
                            {/* <p className="rc-resendcode mt-0">Incorrect Phone Number? <a  href="#" onClick={handleChangeNumber}> Change Number</a> </p>               */}
                        </div>

                        <button className="rg-formbutton" type="submit">Submit</button>


                    </form>
                </main>

            </div>
        </>
    );
};