import React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const PAFilter = ({ handleSearchClickParent,searchParamChangeParent, searchFormDataParent}) => {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {

        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', changeWidth)
    
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    
      }, [])

    const searchParamChange = (event) => {
        event.preventDefault();
        searchParamChangeParent(event);
    }

    const handleSearchClick = (event) => {
        event.preventDefault();
        handleSearchClickParent(event);
    }
    return(
        <div className='row pastagree-topbar'>
            <div className='col-md-12'>
                <Paper
                component="form"
                sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: '100%',
                margin: '0 0 10px',
                boxShadow: 'none',                            
                borderRadius: '5px',
                backgroundColor: '#fff' }}
                >                          
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Party Name"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange = {searchParamChange}
                    value={searchFormDataParent.partyName}
                    name="partyName"
                />
                <IconButton  onClick={handleSearchClick} sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>                          
                </Paper>
            </div>
            {( screenWidth <= 768)&& (<label htmlFor='filter-startdate' className='paf-label-resp'>Start Date</label>)}
            <div className="col-md-4 pr-md-0 pa-searchfilter-resp">
                <input 
                type="date" 
                id='filter-startdate'
                placeholder="Date From" 
                value= {searchFormDataParent.dateFrom}
                name="dateFrom"
                // defaultValue={moment().format('YYYY-MM-DD')} 
                onChange = {searchParamChange}
                className='pastagree-topinput pa-sf-iosdis'/>
            </div>
            {( screenWidth <= 768)&& (<label htmlFor='filter-enddate' className='paf-label-resp'>End Date</label>)}
            <div className="col-md-4 pr-md-0 pa-searchfilter-resp">
                <input 
                type="date" 
                id='filter-enddate'
                placeholder="Date To" 
                value= {searchFormDataParent.dateTo}
                name="dateTo"
                onChange = {searchParamChange}
                // defaultValue={moment().format('YYYY-MM-DD')} 
                className='pastagree-topinput pa-sf-iosdis' />
            </div>                          
            <div className="col-md-4 text-right pastagree-topbutton">
                <Button                              
                color="secondary"                           
                startIcon={<FilterAltIcon />}
                variant="contained"
                onClick={handleSearchClick}
                >
                Filter
                </Button>
            </div>
        </div>  
    );
}

export default PAFilter;