import React from 'react';
import Navbar from '../Navbar/Navbar';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FlashMessage from '../FlashMessage';


export default function ResetPassword( { formSubmit } ){

    const [values, setValues] = React.useState({
        new_password: '',
        confirmPassword: "",
        showPassword: false,
      });
     

      const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      // handle formsubmit 
      const handleFormSubmit = (event) => {
          event.preventDefault();
          formSubmit(event);
      }

    // flash message styling
    const flashMessageStyling = {
        width: '100%'
    }

    return(
        <>
        <div className="rg-maincontainer">
            <main className="rg-form">
                <form onSubmit={handleFormSubmit}>
                    <h1 className="rg-form-heading mb-3">Reset Password</h1>                    
                    <FlashMessage flashCss={flashMessageStyling} />
                    <div className="rg-form-floating">                        
                        <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-newpassword"  className="inputRounded">New Password</InputLabel>
                            <OutlinedInput
                                className='fieldInput resetpass-ipfields'
                                id="outlined-adornment-newpassword"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.new_password}
                                name="new_password"
                                onChange={handleChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="New Password"
                            />
                            </FormControl>
                        
                    </div>
                    <div className="rg-form-floating">
                        <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-confirmpassword"  className="inputRounded">Confirm New Password</InputLabel>
                                <OutlinedInput
                                    className='fieldInput resetpass-ipfields'
                                    id="outlined-adornment-confirmpassword"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    name="confirmPassword"
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Confirm New Password"
                                />
                        </FormControl>               
                    </div>                    
                    <button                       
                        className="rg-formbutton register-btn-special register-btn-mt w-100 " 
                        type="submit">Save</button>                    
                </form>
            </main>   
            </div>            
        </>
    );
};
