import React from 'react';


const FormTwo = ({ data, changeHandler, currentStep }) => {
    return(
        <>
            {
                currentStep == 2 ? (
                    <>
                         <div className='row'>
                            <div className='col-md-12 hp-newagr-form'>
                              <label htmlFor="cousenteeName">Consentee Name</label>
                              <input 
                                type="text" 
                                id = "cousenteeName" 
                                placeholder='Travis Johnson'
                                value={data.party_1_name}
                                onChange={changeHandler}
                                name="party_1_name"
                                disabled
                            />
                            </div>
                            <div className='col-md-12 hp-newagr-form'>
                              <label htmlFor="cousenterName">Consenter Name</label>
                              <input 
                                type="text"                                
                                id="cousenterName" 
                                placeholder='Cameron Consent'
                                value={data.party_2_name}
                                onChange={changeHandler}
                                name="party_2_name"
                              />
                            </div>
                            <div className='col-md-12 hp-newagr-form'>
                              <label htmlFor="cousenteeEmail">Consenter Email</label>
                              <input 
                                type="text" 
                                id = "cousenteeEmail" 
                                placeholder='CameronConsent@gmail.com'
                                value={data.party_2_email}
                                onChange={changeHandler}
                                name="party_2_email"
                                />
                            </div>
                            <div className='col-md-6 hp-newagr-form'>
                              <label htmlFor="hpagrDate">Date</label>
                              <input 
                                type="date" 
                                id = "hpagrDate" 
                                className="consent-dateip minWidthClass"
                                placeholder='Consentee Name'
                                value={data.agreement_date}
                                // onChange={changeHandler}
                                disabled
                                name="agreement_date"
                                />
                            </div>
                            <div className='col-md-6 hp-newagr-form'>
                              <label htmlFor="hpagrTime">Time</label>
                              <input 
                                type="time" 
                                id="hpagrTime" 
                                className="consent-timeip minWidthClass"
                                placeholder='Consentee Name'
                                value={data.agreement_time}
                                // onChange={changeHandler}
                                disabled
                                name="agreement_time"
                                />
                            </div>
                          </div>
                    </>
                ) : ""
            }
        </>
    );
};

export default FormTwo;