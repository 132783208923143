import React from 'react';
import { Helmet } from 'react-helmet-async';
import { overlay, devLog } from '../../utils/Helper';
import { Context } from '../../Store/store';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import Faq from './Faq';


const FaqLogic = () => {
    const [state, dispatch] = React.useContext(Context);
    const [faqs, setFaqs] = React.useState([]);
    
    React.useEffect(() => {
        overlay(true);
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
        HttpRequest.makeRequest(
            ApiRoutes.getFaqs.method,
            ApiRoutes.getFaqs.end_point
        ).then((response) => {
            if(response.resCode === 200){
                overlay(false);
                setFaqs(response.data);
            }else{
                overlay(false);
                dispatch({ type:'SET_STATE', response: response, flash_message_show: true});
            }
        }).catch(err => {
            devLog("error", err);
        });

    },[]);
    
    
    return(
        <>
            <Helmet>
                <title>Consent - FAQ</title>
            </Helmet>
            <Faq faqs={faqs} />
        </>
    );
};

export default FaqLogic;