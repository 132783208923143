import React from 'react';
import Navbar from '../Navbar/Navbar';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import { useHistory } from 'react-router-dom';
import FlashMessage from '../FlashMessage';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getStorageData } from '../../utils/Helper';
import Constants from '../../utils/Constants';


export default function ChangePhoneNumber({ submitForm }){
    const [phone, setPhone] = React.useState('');
    const user = getStorageData(Constants.session_object);

    const history = useHistory();
    const goBack = () => (history.goBack());

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const params = new FormData(form);
        let mobile_no = event.target.phoneNumber.value.split(' ');
        const mobile_code = mobile_no[0];
        mobile_no.shift();
        params.delete('confirmPassword');
        params.delete('phoneNumber');
        params.delete('phoneNumberCountry');
        params.append('mobile_no',mobile_no.join(''));
        params.append('mobile_code',mobile_code);
        submitForm(params,event);
    };

      // flash message styling
      const flashMessageStyling = {
        width: '100%'
    }

    return(
        <>
            <Navbar />
            <div className="hmprofile-main">
                <div className="container hmprofile-main-inner">
                    <div className='row'>                        
                        <div className="col-md-12 hp-newagr-topheading">   
                            <KeyboardBackspaceIcon onClick={goBack} className="mb-4 back-btn-style" style={{cursor: 'pointer'}}/>                        
                            <h1 className='mt-0 heading-backbtn'>Change Phone Number</h1>                            
                        </div>
                        <div className='col-md-12 '>
                            <form onSubmit={handleSubmit}>
                            <div className='hp-newagr-formcontent'>                     
                                <div className='row'>
                                        
                                        <div className='col-md-12 hp-newagr-form phonenum-special mb-3'>
                                        <FlashMessage cssStyles={flashMessageStyling}/>
                                            <label htmlFor="outlined-adornment-password">Old Phone Number</label>
                                            <PhoneInput
                                                disabled
                                                value={user && user.mobile_code && user.mobile_no && user.mobile_code.concat(user.mobile_no)}
                                                onChange={phone => setPhone(phone)}
                                                placeholder="+1 (123) 456-7890"
                                                name="mobile_noAlready"
                                                countryOptionsOrder= {["US", "GB", "AU", "CA", "|", "..."]}
                                                
                                            />
                                        
                                        </div>
                                        <div className='col-md-12 hp-newagr-form phonenum-special mb-3'>
                                            <label htmlFor="cousenterName">New Phone Number <small className='pn-subtext'> (Include Country Code)</small></label>                                       
                                            <PhoneInput
                                                // country={'us'}
                                                value={phone}
                                                defaultCountry="US"
                                                onChange={phone => setPhone(phone)}
                                                placeholder="+1 (123) 456-7890"
                                                name="phoneNumber"
                                                maxlength="16"
                                                countryOptionsOrder= {["US", "GB", "AU", "CA", "|", "..."]}
                                            />
                                        </div>                                   
                                        <div className='col-md-12 text-center'>
                                            <button className="hp-newagr-btn cpass-btn">Save</button>
                                        </div>
                                </div>
                                
                            </div>          
                            </form>                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};