import React from 'react';
import Navbar from '../Navbar/Navbar';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FlashMessage from '../FlashMessage';
import Button from '@mui/material/Button';


export default function ChangePinCode( { submitForm, flashCSS, userObj, forgotPinCodeHandler } ){

    const [values, setValues] = React.useState({
        otp: "",
        otpConfirm: "",
        old_otp: '',
        new_otp: "",
        confirm_otp: "",
    });

    
    const history = useHistory();
    const goBack = () => (history.goBack());

    const handleChange = (event) => {
        setValues({...values, [event.target.name] : event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        submitForm(event);
    };

    const handleForgotPin = () => {
        forgotPinCodeHandler();
    };
    // console.log(userObj)
    return(
        <>
            <Navbar />
            <div className="hmprofile-main">
                <div className="container hmprofile-main-inner">
                    <div className='row'>                        
                        <div className="col-md-12 hp-newagr-topheading">  
                            <KeyboardBackspaceIcon onClick={goBack} className="mb-4 back-btn-style" style={{cursor: 'pointer'}}/>                        
                            <h1 className='mt-0 heading-backbtn'>{userObj.is_past_agreement_otp_set == 0 ? "Set PIN Code" : "Change PIN Code" }</h1>                            
                        </div>
                        <div className='col-md-12 '>
                            <div className='hp-newagr-formcontent'>  
                            <form onSubmit={handleSubmit}>              
                                <div className='row'>                                   
                                    <div className="col-md-12">
                                        <FlashMessage cssStyles={flashCSS}/>
                                    </div>
                                    {userObj.is_past_agreement_otp_set == 0 ?
                                    (
                                    <>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="cousenterName">Set a New PIN Code</label>
                                        <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                            <TextField 
                                            className="phoneNumber" 
                                            id="outlined-basic" 
                                            label="1234" 
                                            variant="outlined"
                                            value={values.otp}
                                            name="otp" 
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 4 }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="cousenterName">Confirm New PIN Code</label>
                                        <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                            <TextField 
                                            className="phoneNumber" 
                                            id="outlined-basic" 
                                            label="1234" 
                                            variant="outlined"
                                            value={values.otpConfirm}
                                            name="otpConfirm" 
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 4 }}
                                            />
                                        </FormControl>
                                    </div>
                                    </>
                                    ) :                                    
                                    
                                    (
                                    <>
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="cousenterName">Old PIN Code</label>
                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <TextField 
                                                className="phoneNumber" 
                                                id="outlined-basic" 
                                                label="1234" 
                                                variant="outlined"
                                                value={values.old_otp}
                                                name="old_otp" 
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                            </FormControl>
                                        </div> 
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="cousenterName">New PIN Code</label>
                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <TextField 
                                                className="phoneNumber" 
                                                id="outlined-basic" 
                                                label="1234" 
                                                variant="outlined" 
                                                value={values.new_otp}
                                                name="new_otp"
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="cousenterName">Confirm PIN Code</label>
                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <TextField 
                                                className="phoneNumber" 
                                                id="outlined-basic" 
                                                label="1234" 
                                                variant="outlined" 
                                                value={values.confirm_otp}
                                                name="confirm_otp"
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                            </FormControl>
                                        </div>
                                        <span className='register-tcs pl-3' onClick={handleForgotPin}>Forgot Pin ?</span>
                                    </> )
                                    }                                  
                                    <div className='col-md-12 text-center'>
                                        <Button 
                                        className="hp-newagr-btn cpass-btn" 
                                        type="submit"
                                        color="secondary"            
                                        variant="contained"
                                        >Save</Button>
                                    </div>
                                                                        
                                </div>
                                </form>     
                            </div>          
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};