import React from 'react';
import { Helmet } from 'react-helmet-async';
import TermsandConditions from './TermsandConds';
import { Context } from '../../Store/store';
import { devLog, overlay } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';

const TermsandConditionsLogic = () => {

    const [state, dispatch] = React.useContext(Context);
    const [record, setRecord] = React.useState([]);

    React.useEffect(() => {
        dispatch({ type: "USE_STATE",  response: [], flash_message_show: false});
        overlay(true);
        HttpRequest.makeRequest(
            ApiRoutes.getAppContents.method, 
            `${ApiRoutes.getAppContents.end_point}?identifier=terms`
        )
        .then( ( response) => {
            if(response.resCode === 200) {
                overlay(false);
                setRecord(response.data)
            }else {
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
            }
        })
        .catch(err => {
            devLog('Error', err);
        });

    }, []);
    return(
        <>
        <Helmet>
            <title>Consent - Terms & Conditions</title>
        </Helmet>
        <TermsandConditions record={record}/>
        </>
    );
}

export default React.memo(TermsandConditionsLogic);