import React from 'react';
import { Helmet } from 'react-helmet-async';
import { devLog, overlay } from '../../utils/Helper';
import { useHistory } from 'react-router-dom';
import { Context } from '../../Store/store';
import ForgotPassword from './ForgotPassword';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';



const ForgotPasswordLogic = () => {
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();

    // form submit 
    const submitForm = (params) => {
        overlay(true);
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
        HttpRequest.makeRequest(
            ApiRoutes.loginGenerateFPCode.method,
            ApiRoutes.loginGenerateFPCode.end_point,
            params
        )
        .then((response) => {
            if(response.resCode === 200){
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                setTimeout(()=>{history.push({
                    pathname:'/na-verification-code',
                    state: response.data
                })},2000);

            }else {
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
            }
        })
        .catch(err => {
            devLog('error', err);
        });

    }

    React.useEffect(() => { 
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });

    }, []);

    return(
        <>
            <Helmet>
                <title>Consent - Forgot Password</title>
            </Helmet>
            <ForgotPassword submitForm={submitForm} />
        </>
    );
};

export default ForgotPasswordLogic;