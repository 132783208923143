
import React from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import FlashMessage from "../FlashMessage/index";
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


export default function Register({ submitForm, record }) {

    const [formData, setFormData] = React.useState({
        'username': '',
        'email': '',
        'password': '',
        'confirmPassword': '',
        'phoneNumber': '',
    });
    const [eye, setEye] = React.useState(true);
    const [type, setType] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState("password");
    const [openTCModal, setOpenTCModal] = React.useState(false);

    const handleChangeInput = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })

    }
    const handleChangePhone = (event) => {
        setFormData({ ...formData, ['phoneNumber']: event });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        submitForm(event);
    };

    const passwordShowHandler = () => {
        showPassword == "password" ? setShowPassword("text") : setShowPassword("password");
        setEye(!eye);
        setType(!type);
    }

    // handle TC modal
    const handleTCModalClose = (e) => {
        setOpenTCModal(false);
    }
    // handle TC modal open
    const handleTCModal = () => {
        setOpenTCModal(true);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,

        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 4,
        textAlign: 'center',
        padding: '10px 20px 30px 20px',
        borderRadius: '10px',
        boxShadow: '0 2px 6px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fff',
        border: '1px solid transparent',
        outline: 'none'

    }


    return (
        <>
            <div className="rg-maincontainer">

                <main className="rg-form">
                    <form onSubmit={handleSubmit}>
                        <h1 className="rg-form-heading">Register</h1>
                        <p className="rg-form-para">Login or Sign Up to Continue</p>
                        <FlashMessage />
                        <div className="rg-form-floating">
                            <label htmlFor="usernameInput">Full Name</label>
                            <input
                                name="username"
                                type="text"
                                className="rg-formcontrol"
                                id="usernameInput"
                                placeholder="Cameron Consent"
                                value={formData && formData.username}
                                onChange={handleChangeInput}
                            />

                        </div>
                        <div className="rg-form-floating">
                            <label htmlFor="useremailInput">Email Address</label>
                            <input
                                name="email"
                                type="email"
                                className="rg-formcontrol"
                                id="useremailInput"
                                placeholder="CameronConsent@gmail.com"
                                value={formData && formData.email}
                                onChange={handleChangeInput}
                            />
                        </div>
                        <div className="rg-form-floating login-pass">
                            <label htmlFor="floatingPassword">Password</label>
                            <input
                                name="password"
                                type={showPassword}
                                className="rg-formcontrol"
                                id="floatingPassword"
                                placeholder="Password"
                                value={formData && formData.password}
                                onChange={handleChangeInput}
                            />
                            {
                                eye ? <VisibilityOffIcon
                                    onClick={passwordShowHandler}
                                    className="fa-eye-slash"
                                /> :
                                    <VisibilityIcon
                                        onClick={passwordShowHandler}
                                        className="fa-eye-slash"
                                    />
                            }
                            {/* <i onClick={passwordShowHandler} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
                        </div>
                        <div className="rg-form-floating login-pass">
                            <label htmlFor="floatingConfirmPassword">Confirm Password</label>
                            <input
                                name="confirmPassword"
                                type={showPassword}
                                className="rg-formcontrol"
                                id="floatingConfirmPassword"
                                placeholder="Confirm Password"
                                value={formData && formData.confirmPassword}
                                onChange={handleChangeInput}
                            />
                            {/* <i onClick={passwordShowHandler} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>     */}
                        </div>
                        <div className="rg-form-floating  phonenum-special mb-3">
                            <label htmlFor="floatingPassword">Phone Number<small > (Include Country Code)</small></label>
                            <PhoneInput
                                name="phoneNumber"
                                defaultCountry="US"
                                value={formData && formData.phoneNumber}
                                onChange={handleChangePhone}
                                placeholder="+1 (123) 456-7890"
                                className="rg-phonenumber-ip"
                                // withCountryCallingCode 
                                // international
                                countryOptionsOrder={["US", "GB", "AU", "CA", "|", "..."]}
                            />
                        </div>
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                            <label className="rg-form-para ft mb-0" htmlFor="exampleCheck1">I agree to <span className='register-tcs' onClick={handleTCModal}>the Terms and Conditions</span></label>
                        </div>

                        <button
                            className="rg-formbutton register-btn-special register-btn-mt "
                            type="submit">Sign Up</button>
                        <p className="rg-form-para ft">Already Registered? <Link to="/login">Login here</Link> </p>
                    </form>
                </main>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openTCModal}
                    onClose={handleTCModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openTCModal}>
                        <Box sx={style} className="modal-responsive">
                            <div className="container hmprofile-main-inner">
                                <div className='row'>
                                    <div className="col-md-12 hp-newagr-topheading">
                                        <h1 className='mt-0'>Terms & Conditions</h1>
                                    </div>
                                    <div className='col-md-12 '>
                                        <div className='hp-newagr-formcontent p-0 register-termcondi'>
                                            {
                                                record && Object.keys(record).length > 0 &&
                                                (<div dangerouslySetInnerHTML={{ __html: record.content }} />)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>

            </div>
        </>
    );
};
