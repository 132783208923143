import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { overlay, devLog, setStorageData} from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import Constants from '../../utils/Constants';
import { Context } from '../../Store/store';
import FlashMessage from '../FlashMessage';
import Button from '@mui/material/Button';


export default function PAPinVerification( {flashCSS, handlePAPinConfirmation, userObj } ){

    const [otp, setOTP] = React.useState("");
    const [pinCode, setPinCode] = React.useState("");
    const [pinVar, setPinVar] = React.useState(userObj.is_past_agreement_otp_set);
    const [state, dispatch] = React.useContext(Context);
    const [values, setValues] = React.useState({
        otp: "",
        otpConfirm: "",
    });

    const handleChange = (event) => {
        setValues({...values, [event.target.name] : event.target.value });
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     submitForm(event);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        if(pinCode == otp){
            handlePAPinConfirmation();
        }else{
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'Incorrect Code'}, flash_message_show: true});
        }
    };

    // handle set pin 
    const handleSubmitSetPin = (event) => {
        event.preventDefault();
        const argOne = values.otp;
        const argTwo = values.otpConfirm;
        if (argOne === "" || argTwo === "" ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, flash_message_show: true});
        }
        else if (argOne.length < 4 || argTwo.length < 4 ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'Length of Pin Code should be 4 characters!'}, flash_message_show: true});
        } else if ( argOne !== argTwo ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'New pin and confirm new pin must be same'}, flash_message_show: true});
        } 
        else{           
            // const form = event.target;
            const params = new FormData();
            params.append('otp', values.otp);
            overlay(true);
            dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
            HttpRequest.makeRequest(
                ApiRoutes.setPastAgreeOTP.method,
                ApiRoutes.setPastAgreeOTP.end_point,
                params
            ).then((response) => {
                if ( response.resCode === 200){
                    isPAOTPSetUpdate();
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true});                
                    // setTimeout(() => {
                    //     history.push('/profile');
                    // }, 2000);
                }else {
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                }
            }).catch(err => {
                devLog("error", err);
            });

        }
    }

    // update profile for isPAOTPSET
    const isPAOTPSetUpdate = () => {
        const params = new FormData();
      let otpPA = !(userObj && userObj.is_past_agreement_otp_set);
      otpPA = otpPA ? "1" : "0";
      params.append('is_past_agreement_otp_set', otpPA);
      HttpRequest.makeRequest(
        ApiRoutes.updateUserProfile.method,
        ApiRoutes.updateUserProfile.end_point,
        params
      )
      .then( (response) => {
          if(response.resCode === 200){
            //   overlay(false);
            //   dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
              setStorageData(Constants.session_object, response.data);   
            //   setPinVar(1);
              setTimeout(() => {handlePAPinConfirmation()},2000);           
          }else{
              overlay(false);
              dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
          }
      })
      .catch(err => {
          devLog("error", err);
      });
    }

    React.useEffect(() => {
       
        if(userObj.is_past_agreement_otp_set === 1){
            overlay(true);
            dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
            HttpRequest.makeRequest(
                ApiRoutes.getPastAgreeOTP.method,
               ApiRoutes.getPastAgreeOTP.end_point
            ).then((response) => {
                if ( response.resCode === 200){
                    overlay(false);
                    // dispatch({ type: "SET_STATE", response: response, flash_message_show: true});                
                    setPinCode(response.data.otp);
                }else {
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                }
            }).catch(err => {
                devLog("error", err);
            });

        }
    }, [pinVar]);
    // console.log('pinCode',pinCode)
    return(
        <>                                         
            <div className='row'>                                   
                <div className="col-md-12">
                    <FlashMessage cssStyles={flashCSS}/>
                </div>
            
                {pinVar === 1 ? 
                    (
                        <>
                            <div className='col-md-12 hp-newagr-form'>
                                <label htmlFor="cousenterName">Please insert your PIN Code</label> 
                                <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                    <TextField 
                                    className="phoneNumber" 
                                    id="outlined-basic" 
                                    label="1234" 
                                    variant="outlined"
                                    value={otp}
                                    name="otp" 
                                    onChange={e => setOTP(e.target.value)}
                                    inputProps={{ maxLength: 4 }}
                                    />
                                </FormControl>
                            </div>
                                                            
                            <div className='col-md-12 text-center'>
                                <Button 
                                className="hp-newagr-btn cpass-btn" 
                                onClick={handleSubmit}
                                color="secondary"            
                                variant="contained"
                                >Submit</Button>
                            </div>
                        
                        </>
                    ):(
                        <>
                        {/* <form onSubmit={handleSubmitSetPin}> */}
                            <div className='col-md-12 hp-newagr-form'>
                                <p className="font-weight-bold">Please set a New PIN Code first</p>
                                <label htmlFor="cousenterName">Set a New PIN Code</label>
                                <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                    <TextField 
                                    className="phoneNumber" 
                                    id="outlined-basic" 
                                    label="1234" 
                                    variant="outlined"
                                    value={values.otp}
                                    name="otp" 
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-md-12 hp-newagr-form'>
                                <label htmlFor="cousenterName">Confirm New PIN Code</label>
                                <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                    <TextField 
                                    className="phoneNumber" 
                                    id="outlined-basic" 
                                    label="1234" 
                                    variant="outlined"
                                    value={values.otpConfirm}
                                    name="otpConfirm" 
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 4 }}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-md-12 text-center'>
                                <Button 
                                className="hp-newagr-btn cpass-btn" 
                                // type="submit"
                                color="secondary"            
                                variant="contained"
                                onClick={handleSubmitSetPin}
                                >Save</Button>

                            </div>
                        {/* </form> */}
                        </>
                    )
                    }
                                                    
            </div>
        </>
    );
};