import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../Navbar/Navbar';
import { Link, useHistory } from 'react-router-dom';
import { Context } from '../../Store/store';
import { devLog, getStorageData, overlay, removeStorageData, setStorageData } from '../../utils/Helper';
import ApiRoutes from '../../Services/ApiRoutes';
import HttpRequest from '../../Services/HttpRequest';
import Constants from '../../utils/Constants';

export default function Setting() {
  const [state, dispatch] = React.useContext(Context);
  const [changeOtp, setChangeOtp] = React.useState(false);
  const history = useHistory();
  const user = getStorageData(Constants.session_object);


  React.useEffect(() => {

  }, [changeOtp]);

  const handleLogout = () => {
    overlay(true);
    HttpRequest.makeRequest(
      ApiRoutes.logoutUser.method,
      ApiRoutes.logoutUser.end_point
    )
      .then((response) => {
        if (response.resCode === 200) {
          overlay(true);
          setTimeout(() => {
            removeStorageData();
          }, 2000);
        } else {
          dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
          overlay(false);
        }
      })
      .catch(err => {
        devLog("error", err);
      });
  };

  const handleChange = (event) => {
    // const form = event.target;
    const params = new FormData();
    let otpPA = !(user && user.is_otp_for_pa);
    otpPA = otpPA ? "1" : "0";
    params.append('is_otp_for_pa', otpPA);
    HttpRequest.makeRequest(
      ApiRoutes.updateUserProfile.method,
      ApiRoutes.updateUserProfile.end_point,
      params
    )
      .then((response) => {
        if (response.resCode === 200) {
          overlay(false);
          dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
          setStorageData(Constants.session_object, response.data);
          setChangeOtp(!changeOtp);

        } else {
          overlay(false);
          dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
        }
      })
      .catch(err => {
        devLog("error", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>Consent - Setting</title>
      </Helmet>
      <Navbar />
      <div className="hmprofile-main">
        <div className="container hmprofile-main-inner">
          <div className='row'>
            <div className="col-md-12 hp-newagr-topheading">
              {/* <KeyboardBackspaceIcon onClick={goBack} className="mb-4" style={{cursor: 'pointer'}}/> */}
              <h1 className='mt-0'>Settings</h1>
            </div>
            <div className='col-md-12 '>
              <div className='hp-newagr-formcontent'>
                <div className='d-flex justify-content-between mb-4 align-items-baseline'>
                  <div className='hp-natoggle-content setting-special  d-flex justify-content-end align-items-center'>
                    <span className="setting-icon setting-span-p" >
                      <img className="setting-span" src="./assets/images/setting-icon3x.png" alt="pin code icon" />
                    </span>
                    <p className=''>Pin Code Required for Past Consents</p>
                  </div>
                  <div className='hp-natoggle-btn ml-3'>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={user && user.is_otp_for_pa}
                        name="is_otp_for_pa"
                        onChange={handleChange}
                      />
                      <span className=" hpnatoggle-slider round"></span>
                    </label>
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-4'>
                  <div className='hp-natoggle-content setting-special mb-2'>
                    <span className="setting-icon setting-icon-special setting-span-prsc  " >
                      <img className="setting-span" src="./assets/images/resource-icon3x.png" alt="resource code icon" />
                    </span>
                    <Link to="/resources" >Resources</Link>
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-4'>
                  <div className='hp-natoggle-content setting-special mb-2'>
                    <span className="setting-icon setting-icon-special setting-span-prsc" >
                      <img className="setting-span" src="./assets/images/resource-icon3x.png" alt="termscond code icon" />
                    </span>
                    <Link to="/termsconds" >Terms and Conditions</Link>
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-4'>
                  <div className='hp-natoggle-content setting-special mb-2'>
                    <span className="setting-icon setting-span-pli" >
                      <img className="setting-span" src="./assets/images/law-icon3x.png" alt="privacy policy icon " />
                    </span>
                    <Link to="/privacypolicy" >Privacy Policy</Link>
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-4'>
                  <div className='hp-natoggle-content setting-special mb-2'>
                    <span className="setting-icon setting-span-ps">
                      <img className="setting-span" src="./assets/images/aprocedures-icon3x.png" alt="arbitration procedures icon " />
                    </span>
                    <Link to="/aprocedures" >Arbitration Procedures</Link>
                  </div>
                </div>
                <div className='d-flex justify-content-between mb-0'>
                  <div className='hp-natoggle-content setting-special mb-0'>
                    <span className="setting-icon setting-span-pl">
                      <img className="setting-span" src="./assets/images/logout-icon3x.png" alt="logout icon " />
                    </span>
                    <span className="logout-btn logout-ml-resp v-middle " onClick={handleLogout}>Logout</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}