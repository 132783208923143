import React from 'react';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Resources = ({ record }) => {
    const history = useHistory();
    const goBack = () => (history.goBack());
    
    return(
        <>
         <Navbar />
         <div className="hmprofile-main">
            <div className="container hmprofile-main-inner">
                <div className='row'>
                    <div className="col-md-12 hp-newagr-topheading">
                        <KeyboardBackspaceIcon onClick={goBack} className="mb-4 back-btn-style" style={{cursor: 'pointer'}}/>
                        <h1 className='mt-0 heading-backbtn'>Resources</h1>
                    </div>
                    <div className='col-md-12 ' >
                        <div className='hp-newagr-formcontent setting-resources  link-limit'>
                            {
                                record && record.length > 0 && record.map(el => {
                                    return (
                                            <div key={el.id}>
                                                <p className="font-weight-bold">{el.title}</p>
                                                <p>{el.descriptions}</p>
                                                <a target="_blank" href={el.url}>{el.url}</a>   
                                                <hr />                                 
                                            </div>          
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default React.memo(Resources);