import React from 'react';
import { useHistory } from 'react-router-dom';
import VerificationCode from './VerificationCode';
import { Context } from '../../Store/store';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import Constants from '../../utils/Constants';

const VCLogic = (props) => {
    const [state, dispatch] = React.useContext(Context);
    console.log("props",props)

    const deviceToken = 123456789;
    const device_type = 'web';
    const history = useHistory();
    const mobile_no = props && props.history && props.history.location && props.history.location.state.mobile_no;
    const mobile_code = props && props.history && props.history.location && props.history.location.state.mobile_code;
    const forget_token = props && props.history && props.history.location && props.history.location.state.forget_token;
    const email = props && props.history && props.history.location && props.history.location.state.email;
    const type = "registration";

    const submitForm = (params) => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
        overlay(true);
        params.append('device_type', device_type);
        params.append('device_token', deviceToken);
        HttpRequest.makeRequest(
            ApiRoutes.verificationCode.method,
            ApiRoutes.verificationCode.end_point,
            params
        ).then((response) => {
            if (response.resCode === 200) {
                overlay(false);
                dispatch({
                    type: "SET_STATE",
                    response: { status: true, resCode: 200, data: [], message: 'Verification Successful.' },
                    flash_message_show: true
                });
                setTimeout(() => { history.push("/login") }, 3000);
            } else {
                dispatch({
                    type: 'SET_STATE',
                    response: response,
                    flash_message_show: true
                });
                overlay(false);
            }
        }).catch(err => {
            devLog("error", err);
        });
    };

    const handleResend = () => {
        dispatch({type: "SET_STATE", response: [], flash_message_show: false});
        overlay(true);

        const params = new FormData();
        params.append("type","registration");
        // params.append("mobile_code",mobile_code);
        // params.append("mobile_no",mobile_no);
        params.append("forget_token",forget_token);
        params.append("email",email);
        params.append("code_resend",1)
        HttpRequest.makeRequest(
            ApiRoutes.loginGenerateFPCode.method,
            ApiRoutes.loginGenerateFPCode.end_point,
            params)
            .then((response) => {
                if(response.resCode === 200){
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                    setTimeout(()=>{history.push({
                        pathname:'/verification-code',
                        state: response.data
                    })},2000);
                }else{
                    dispatch({type:'SET_STATE', response: response, flash_message_show: true})
                    overlay(false);
                }
            }).catch(err=>{
            devLog("error",err);
        });
    };

    React.useEffect(() => {
        dispatch({
            type: 'SET_STATE',
            response: [],
            flash_message_show: false
        });
    }, []);


    return (
        <>
            <VerificationCode  handleResend={handleResend} submitForm={submitForm} />
        </>
    );
}

export default VCLogic;