import React from 'react';
import { Helmet } from 'react-helmet-async';
import ChangePass from './ChangePass';
import { devLog, overlay, regexPassword, passwordInstruction } from '../../utils/Helper';
import { Context } from '../../Store/store';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes'; 
import { useHistory } from 'react-router-dom';

const ChangePassLogic = () => {

    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    
    const submitForm = (event) => {
        const pass = event.target.password.value; 
        const newPass = event.target.new_password.value;
        const confirmPass = event.target.confirmPassword.value;

        if( pass === "" || newPass === "" || confirmPass === ""){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
                });
        }
        else if (newPass !== confirmPass){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'new password and confirm password mismatch'}, 
                flash_message_show: true
            });
        }else if(pass === newPass){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Current Password and New Password must be different'}, 
                flash_message_show: true
            });        }
        else{
            if( regexPassword.test(newPass)){
                apiCallFunction(event);
            }else{
                dispatch({
                    type: 'SET_STATE', 
                    response:{status: false, resCode: 400, data: null,message: passwordInstruction}, 
                    flash_message_show: true
                }); 
            }
        }
    };

    // sumbit function apicall
    const apiCallFunction = (event) => {
        const form = event.target;
        let params = new FormData(form);
        params.delete('confirmPassword');
        overlay(true);
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
        HttpRequest.makeRequest(
            ApiRoutes.updatePassword.method,
            ApiRoutes.updatePassword.end_point,
            params
        ).then((response) => {
            if (response.resCode === 200){
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                setTimeout(() => {
                    history.push('/profile')
                }, 2000);
            }else{
                dispatch({ type: "SET_STATE", response:response, flash_message_show: true});
                overlay(false);
            }
        }).catch(err => {
            devLog("error", err);
        });
      }

      // flash message styling
    const flashMessageStyling = {
        width: '100%'
    }

    return(
        <>
            <Helmet>
                <title>Consent - Change Password</title>
            </Helmet>
            <ChangePass submitForm = {submitForm} flashCss={flashMessageStyling} />
        </>
    );
};

export default ChangePassLogic;