import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/Home/Home";
import Setting from "../components/Setting/Setting";
import Contactus from "../components/Contactus/ContactUsLogic";
import FAQ from "../components/FAQ/FaqLogic";
import Resources from '../components/Resources/ResourcesLogic';
import TermsandCons from '../components/TermsandConditions/TermsandCondsLogic';
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicyLogic";
import ArbitrationProcedures from "../components/AProcedures/AProceduresLogic";
import Profile from '../components/profile/Profile';
import EditSignature from '../components/profile/EditSignature';
import ChangePass from "../components/ChangePassword/ChangePassLogic";
import ChangePhoneNumberLogic from "../components/ChangePhoneNumber/ChangePhoneNumberLogic";
import ChangePinCode from "../components/ChangePinCode/ChangePinCodeLogic";
import NotFound from "../components/pages/NotFound/NotFound";



const PrivateRoutes = () => {
  return (
    <Switch>
      <Route exact path={"/home"} component={Home} />
      <Route exact path={"/resources"} component={Resources} />
      <Route exact path={"/termsconds"} component={TermsandCons} />
      <Route exact path={"/privacypolicy"} component={PrivacyPolicy} />
      <Route exact path={"/aprocedures"} component={ArbitrationProcedures} />
      <Route exact path={"/change-pass"} component={ChangePass} />
      <Route exact path={"/change-num"} component={ChangePhoneNumberLogic} />
      <Route exact path={"/edit-sig"} component={EditSignature} />
      <Route exact path={"/change-pin"} component={ChangePinCode} />
      <Route exact path={"/profile"} component={Profile} />
      <Route exact path={"/setting"} component={Setting} />
      {/* <Route exact path={"/contactus"} component={Contactus} /> */}
      <Route exact path={"/faq"} component={FAQ} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default PrivateRoutes;
