// "use strict"

const constants = require('../utils/Constants');

module.exports = {
    //Register User API
    userRegister: {
        end_point: constants.baseURLLocal + 'auth/register',
        method: "POST"
    },
    //VerifyCode
    verificationCode: {
        end_point: constants.baseURLLocal + 'api/verify_code',
        method: "POST"
    },
    //ResendCode
    resendVerificationCode: {
        end_point: constants.baseURLLocal + 'api/generate_code',
        method: "POST"
    },
     //Login User
     loginUser: {
        end_point: constants.baseURLLocal + 'auth/login',
        method: "POST"
    },
    //Update User Profile
    updateUserProfile: {
        end_point: constants.baseURLLocal + 'api/update_user_profile',
        method: "POST"
    },
     //Update Password
     updatePassword: {
        end_point: constants.baseURLLocal + 'auth/update_password',
        method: "POST"
    },
    //Logout User
    logoutUser: {
        end_point: constants.baseURLLocal + 'auth/logout',
        method: "GET"
    },
     //Contact us
     contactusOptions: {
        end_point: constants.baseURLLocal + 'api/get_contact_opt',
        method: "GET"
    },
     //Contact us send message
     contactusSendMessage: {
        end_point: constants.baseURLLocal + 'api/add_contact',
        method: "POST"
    },
      //get FAQ
      getFaqs: {
        end_point: constants.baseURLLocal + 'api/get_faq_web',
        method: "GET"
    },
        //get Abide Rules Clasuses
        getAbideRulesClasuses: {
        end_point: constants.baseURLLocal + 'api/abiderules-clauses',
        method: "GET"
    },
    //get BP Clasuses
    getPBClasuses: {
        end_point: constants.baseURLLocal + 'api/bpb-clauses',
        method: "GET"
    },
    //no Auth generate pass
    loginGenerateFPCode: {
    end_point: constants.baseURLLocal + 'no_auth/generate_code',
    method: "POST"
    },
      //no Auth verify pass
      loginVerifyCode: {
        end_point: constants.baseURLLocal + 'no_auth/verify_code',
        method: "POST"
    },
    //no Auth reset pass
    loginResetPass: {
        end_point: constants.baseURLLocal + 'auth/resetPassword',
        method: "POST"
    },
    //verify mobile no
    verifyMobileNo: {
        end_point: constants.baseURLLocal + 'api/check_valid_number',
        method: "POST"
    },
    //update otp PA
    updatePastAgreeOTP: {
        end_point: constants.baseURLLocal + 'api/update_otp',
        method: "POST"
    },
     //set otp PA
     setPastAgreeOTP: {
        end_point: constants.baseURLLocal + 'api/set_otp',
        method: "POST"
    },
    //get otp PA
    getPastAgreeOTP: {
        end_point: constants.baseURLLocal + 'api/get_otp',
        method: "GET"
    },
    //forgot otp PA
    forgotPastAgreeOTP: {
        end_point: constants.baseURLLocal + 'api/forget_otp',
        method: "POST"
    },
    //get contract list
    getContractList: {
        end_point: constants.baseURLLocal + 'api/get_contracts',
        method: "GET"
    }, 
    //get contract detail
    getContractDetail: {
        end_point: constants.baseURLLocal + 'api/get_contract',
        method: "GET"
    },
    //get rest options
    getRestOptions: {
        end_point: constants.baseURLLocal + 'api/get_rest_options',
        method: "GET"
    },
    //get agree options
    getAgreeOptions: {
        end_point: constants.baseURLLocal + 'api/get_options',
        method: "GET"
    },
    // get make contract
    getMakeContract: {
        end_point: constants.baseURLLocal + 'api/make_contract',
        method: "POST"
    },
    // get resources
    getResources: {
        end_point: constants.baseURLLocal + 'api/get_resources',
        method: "GET"
    },
     // get app_contents
     getAppContents: {
        end_point: constants.baseURLLocal + 'api/get_content_web',
        method: "GET"
    },
    // user subscription
    user_subscription : {
        end_point: constants.baseURLLocal + 'api/user/stripe-subscription',
        method: 'POST'
    },
      // send Attachments ( purple button )
      sendAttachments : {
        end_point: constants.baseURLLocal + 'api/contract/attachment',
        method: 'POST'
    },
    getSubscriptionHistory : {
        end_point: constants.baseURLLocal + 'api/subscriptions/list',
        method: 'GET'
    },
    subscriptionCancel : {
        end_point: constants.baseURLLocal + 'api/user/subscription-cancel',
        method: 'GET'
    },
    getSubsStatus:  {
        end_point: constants.baseURLLocal + 'api/user/subscription',
        method: 'GET'
    },
      // social login 
      socialLogin : {
        end_point: constants.baseURLLocal + 'auth/user/social-login',
        method: 'POST'
    },
      // send sms mobile number 
       sendmsgPN : {
        end_point: constants.baseURLLocal + 'api/send_msg_number',
        method: 'POST'
    } 
    
}