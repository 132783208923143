import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import ChangePhoneNumber from './ChangePhoneNumber';
import { Context } from '../../Store/store';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes'; 
import Constants from '../../utils/Constants';

const ChangePhoneNumberLogic = () => {

    const history = useHistory();
    const [state, dispatch] = React.useContext(Context);
    const [varState, setVarState] = React.useState(false);
    const [paramsForm, setParamsForm] = React.useState({});

    // console.log(paramsForm);
    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
        // return false;
        if (varState){
          
                // overlay(true);
                // dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
                HttpRequest.makeRequest(
                    ApiRoutes.updateUserProfile.method,
                    ApiRoutes.updateUserProfile.end_point,
                    paramsForm
                )
                .then( (response) => {
                    if(response.resCode === 200){
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                        setStorageData(Constants.session_object, response.data);
                        // setVerState(true);
                        setTimeout(() => {
                            history.push('/profile');
                            // setVarState(false);
                        }, 2000);
                    }else{
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                    }
                }) 
                .catch(err => {
                    devLog("error", err);
                });
        }
    }, [varState]);

    // verify phone number
    const submitForm = (params,event) => {
        // console.log('mobileno:',event.target.mobile_noAlready.value, event.target.phoneNumber.value)
        if( event.target.phoneNumber.value === ""){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
                });
        }
        else{
            setParamsForm(params);
            overlay(true);
            dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
            HttpRequest.makeRequest(
                ApiRoutes.verifyMobileNo.method,
                ApiRoutes.verifyMobileNo.end_point,
                params
            )
            .then( (response) => {
                if(response.resCode === 400){
                    setVarState(true);
                }else if(response.resCode == 410){
                    // console.log('there')
                    overlay(false);
                    dispatch({ type: "SET_STATE", response:{status: false, resCode: 400, data: null, message: 'Cannot change phone number if user has signed in through Facebook or Google.'}, flash_message_show: true});
                    // return false;
                }
                else{
                    // console.log('there else')
                    overlay(false);
                    dispatch({ type: "SET_STATE", response:{status: false, resCode: 400, data: null, message: 'Mobile Number is associated with some other user'}, flash_message_show: true});
                }
            })
            .catch(err => {
                devLog("error", err);
            });
        }
    };
    return(
        <>
        <Helmet>
            <title>Consent - Change Phone Number</title>
        </Helmet>
        <ChangePhoneNumber submitForm = { submitForm } />
        </>
    );
};

export default ChangePhoneNumberLogic;