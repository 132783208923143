const _ = require("lodash");
const Helper = require('../utils/Helper');

function Index()
{
    let getLang = _.isEmpty(Helper.getStorageData('accept_lang')) ? 'en' : Helper.getStorageData('accept_lang');
    return require('./' + getLang);
}

export const Language = Index();
