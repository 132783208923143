import React from 'react';
import Navbar from '../Navbar/Navbar';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FlashMessage from '../FlashMessage';

export default function ChangePass( { flashCss, submitForm} ){
    
    const [values, setValues] = React.useState({
        password: '',
        new_password: '',
        confirmPassword: "",
        showPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
      });

     

      const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
      const handleClickShowNewPassword = () => {
        setValues({
          ...values,
          showNewPassword: !values.showNewPassword,
        });
      };
      const handleClickShowConfirmPassword = () => {
        setValues({
          ...values,
          showConfirmPassword: !values.showConfirmPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      
      const history = useHistory();
      const goBack = () => (history.goBack());

      // handle submit
      const handleFormSubmit = (event) => {
        event.preventDefault();
        submitForm(event);
      }

    return(
        <>
            <Navbar />
            <div className="hmprofile-main">
                <div className="container hmprofile-main-inner">
                    <div className='row'>                        
                        <div className="col-md-12 hp-newagr-topheading"> 
                            <KeyboardBackspaceIcon onClick={goBack} className="mb-4 back-btn-style" style={{cursor: 'pointer'}}/>                         
                            <h1 className='mt-0 heading-backbtn'>Change Password</h1>                            
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <div className='col-md-12 '>
                                <div className='hp-newagr-formcontent'>                     
                                    <div className='row'>   
                                        <div className="col-md-12">
                                            <FlashMessage cssStyles={flashCss} />
                                        </div>                             
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="outlined-adornment-password">Current Password</label>                                  

                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password"  className="inputRounded">Password</InputLabel>
                                                <OutlinedInput
                                                    className='fieldInput'
                                                    id="outlined-adornment-password"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    name="password"
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                                </FormControl>
                                        
                                        </div>
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="outlined-adornment-newpassword">New Password</label>
                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-newpassword"  className="inputRounded">New Password</InputLabel>
                                                <OutlinedInput
                                                    className='fieldInput'
                                                    id="outlined-adornment-newpassword"
                                                    type={values.showNewPassword ? 'text' : 'password'}
                                                    value={values.new_password}
                                                    name="new_password"
                                                    onChange={handleChange}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="New Password"
                                                />
                                                </FormControl>
                                        </div>
                                        <div className='col-md-12 hp-newagr-form'>
                                            <label htmlFor="outlined-adornment-confirmpassword">Confirm New Password</label>
                                            <FormControl sx={{  marginBottom: '20px',width: '100%' }}  variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-confirmpassword"  className="inputRounded">Confirm New Password</InputLabel>
                                                <OutlinedInput
                                                    className='fieldInput'
                                                    id="outlined-adornment-confirmpassword"
                                                    type={values.showConfirmPassword ? 'text' : 'password'}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    name="confirmPassword"
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        >
                                                        {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="Confirm New Password"
                                                />
                                                </FormControl>
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            <button type="submit" className="hp-newagr-btn cpass-btn">Save</button>
                                        </div>
                                                                            
                                    </div>
                                    
                                </div>          
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};