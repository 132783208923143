import React from 'react';
import FormOne from './FormOne';
import FormTwo from './FormTwo';
import FormFinal from './FormFinal';
import FromPreOne from './FormPreOne';
import { overlay, devLog, handleMCCheckBox, removeOrAddElement, getStorageData, setStorageData } from '../../utils/Helper';
import Constant from '../../utils/Constants';
import FlashMessage from '../FlashMessage';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import { Context } from '../../Store/store';
import SignaturePad from 'react-signature-canvas';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
// import { Backdrop } from '@mui/material';

const MainController = ({ handleModal }) => {
    let sigPad = {};
    const handleClearButton = () => {
        sigPad.clear()
    }
    const user = getStorageData(Constant.session_object);

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [openSignatureModal, setOpenSignatureModal] = React.useState(false);
    const [openSignatureTwoModal, setOpenSignatureTwoModal] = React.useState(false);
    const [state, dispatch] = React.useContext(Context);
    const [formData, setFormData] = React.useState({
        restrictions: [],
        consents: [],
        disagreed_option: [],
        agreed_option: [],
        abiderules: [],
        purplebutton: [],
        party_1_name: user.username,
        party_2_name: "",
        party_2_email: "",
        additional_text: "",
        agreement_date: moment().format("YYYY-MM-DD"),
        agreement_time: moment().format('HH:mm'),
        party_2_signature: "",
        party_1_signature: user.signature_image,
        is_purple_button_enabled: false
    });
    const [openSigErrorModal, setOpenSigErrorModal] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [consenteeVar, setConsenteeVar] = React.useState(false);
    const [errMsgModal, setErrMsgModal] = React.useState(false);
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    // update time 
    React.useEffect(() => {
        let TimeId = setInterval(() => setFormData({ ...formData, agreement_date: moment().format("YYYY-MM-DD"), agreement_time: moment().format('HH:mm') }), 60000);
        return () => {
            clearInterval(TimeId);
        };
    });
    const next = () => {
        if (currentStep === 0 && formData.agreed_option.length == 0) {
            setErrMsgModal(true);
            // dispatch({
            //     type: 'SET_STATE', 
            //     response:{status: false, resCode: 400, data: null, message: 'Select at least one option!'}, 
            //     flash_message_show: true
            // });
        }
        else if (currentStep === 2 && formData.party_2_name == "") {
            scrollToTop();
            dispatch({
                type: 'SET_STATE',
                response: { status: false, resCode: 400, data: null, message: 'Consenter name is required!' },
                flash_message_show: true
            });
        }
        else {
            dispatch({
                type: 'SET_STATE',
                flash_message_show: false
            });
            let newNextStep = currentStep >= 2 ? 3 : currentStep + 1;
            setCurrentStep(newNextStep);
        }
    };
    const handleSigErrorModalClose = () => {
        setOpenSigErrorModal(false);
    }
    const goBackFunc = () => {
        let backStep = currentStep <= 1 ? 0 : currentStep - 1;
        setCurrentStep(backStep);
    }

    const handleErrMsgModalClose = () => {
        setErrMsgModal(false);
    }

    React.useEffect(() => {
        // dispatch({ type: "USE_STATE", response: [], flash_message_show: false});
        overlay(true);
        Promise.all([getAgreeOptions(), getRestOptions(), getAbideRules(), getPurpleButtonClasuses()])
            .then(([getAgreeOptions, getRestOptions, getAbideRules, getPurpleButtonClasuses]) => {
                if (getAgreeOptions) {
                    if (getAgreeOptions.resCode == 200) {
                        overlay(false);
                        getAgreeOptions.data.map((el) => {
                            el.value = false;
                        });
                        // setFormData({...formData,consents: [...getAgreeOptions.data]});
                        // dispatch({type:"SET_STATE", response:getAgreeOptions, flash_message_show: true});
                    } else {
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: getAgreeOptions, flash_message_show: true });
                    }
                }
                if (getRestOptions) {
                    if (getRestOptions.resCode == 200) {
                        overlay(false);
                        getRestOptions.data.map((el) => {
                            el.value = false;
                        });
                        // setFormData({...formData,restrictions: [...getRestOptions.data]});
                        // dispatch({type:"SET_STATE", response:getRestOptions, flash_message_show: true});
                    } else {
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: getRestOptions, flash_message_show: true });
                    }
                }
                if (getAbideRules) {
                    if (getAbideRules.resCode == 200) {
                        overlay(false);
                        // setFormData({...formData,restrictions: [...getRestOptions.data]});
                        // dispatch({type:"SET_STATE", response:getRestOptions, flash_message_show: true});
                    } else {
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: getAbideRules, flash_message_show: true });
                    }
                }
                if (getPurpleButtonClasuses) {
                    if (getPurpleButtonClasuses.resCode == 200) {
                        overlay(false);
                        // setFormData({...formData,restrictions: [...getRestOptions.data]});
                        // dispatch({type:"SET_STATE", response:getRestOptions, flash_message_show: true});
                    } else {
                        overlay(false);
                        dispatch({ type: "SET_STATE", response: getPurpleButtonClasuses, flash_message_show: true });
                    }
                }
                setFormData({ ...formData, consents: [...getAgreeOptions.data], restrictions: [...getRestOptions.data], abiderules: [...getAbideRules.data], purplebutton: [...getPurpleButtonClasuses.data] })
            })
            .catch(err => {
                devLog('error', err);
            });
    }, []);

    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
    }, [currentStep]);
    const getRestOptions = () => {
        return HttpRequest.makeRequest(
            ApiRoutes.getRestOptions.method,
            ApiRoutes.getRestOptions.end_point
        )
    }
    const getAgreeOptions = () => {
        return HttpRequest.makeRequest(
            ApiRoutes.getAgreeOptions.method,
            ApiRoutes.getAgreeOptions.end_point
        )
    }
    const getAbideRules = () => {
        return HttpRequest.makeRequest(
            ApiRoutes.getAbideRulesClasuses.method,
            ApiRoutes.getAbideRulesClasuses.end_point
        )
    }
    const getPurpleButtonClasuses = () => {
        return HttpRequest.makeRequest(
            ApiRoutes.getPBClasuses.method,
            ApiRoutes.getPBClasuses.end_point
        )
    }


    // handle signature modal
    const handleSignatureModalClose = (e) => {
        setOpenSignatureModal(false);
    }
    // handle signature modal open
    const handleSigModal = () => {
        setOpenSignatureModal(true);
    }
    // handle signature two modal
    const handleSignatureTwoModalClose = () => {
        setOpenSignatureTwoModal(false);
    }

    // handle signature modal open
    const handleSigTwoModal = () => {
        setOpenSignatureTwoModal(true);
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,

        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 4,
        textAlign: 'center',
        padding: '10px 20px 30px 20px',
        borderRadius: '10px',
        boxShadow: '0 2px 6px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fff',
        border: '1px solid transparent',
        outline: 'none'

    }
    const goBackButton = () => {
        return (
            <>
                {
                    currentStep !== 0 ? (
                        <>
                            {/* <div > */}
                            <Button
                                className={" hp-newagr-btn hp-mts-btn"}
                                onClick={goBackFunc}
                                color="secondary"
                                variant="contained"
                            >Back</Button>
                            {/* </div> */}
                        </>
                    ) : ""
                }
            </>
        )
    }

    const nextButton = () => {
        return (
            <>
                {
                    currentStep !== 3 ? (
                        <>
                            {/* <div > */}
                            <Button
                                className="hp-newagr-btn hp-mbresp-btn consent-form-next"
                                onClick={next}
                                color="secondary"
                                variant="contained"
                            >Next</Button>
                            {/* </div> */}
                        </>
                    ) : ""
                }

            </>
        );
    };

    const handleChangeCheckBox = (event) => {
        if (event.formConsent === "resConsent") {
            const modifiedList = removeOrAddElement(formData.disagreed_option, event.id);
            const newArray = handleMCCheckBox(formData.restrictions, event.id);
            setFormData({ ...formData, restrictions: newArray, disagreed_option: modifiedList });
        } else if (event.formConsent === "consent") {
            const modifiedList = removeOrAddElement(formData.agreed_option, event.id);
            const newArray = handleMCCheckBox(formData.consents, event.id);
            setFormData({ ...formData, consents: newArray, agreed_option: modifiedList });
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleChangePurpleBtn = (event) => {
        setFormData({ ...formData, [event.target.name]: !formData[event.target.name] });
    };

    const scrollTotop = () => {
        window.scrollTo(0, 0);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.party_1_signature !== null) {
            if (formData.party_2_signature !== "") {
                overlay(true);
                dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
                const form = event.target;
                const params = new FormData(form);
                params.append("agreed_option", formData.agreed_option);
                params.append("restricted_options", formData.disagreed_option);
                let signatureImg = user.signature_image;
                if (user && user.signature_image && user.signature_image.includes(Constant.baseURLLocal)) {
                    signatureImg = user.signature_image.replace(Constant.baseURLLocal, "");
                }
                params.append("party_1_signature", signatureImg);
                params.append("party_2_signature", formData.party_2_signature);
                params.append("party_1_name", formData.party_1_name);
                params.append("party_2_name", formData.party_2_name);

                params.append("agreement_date", moment(formData.agreement_date).format("MM-DD-YYYY"));
                params.append("agreement_time", formData.agreement_time);
                params.append("party_2_email", formData.party_2_email);
                params.append("is_purple_button_enabled", formData.is_purple_button_enabled == true ? "1" : "0");

                HttpRequest.makeRequest(
                    ApiRoutes.getMakeContract.method,
                    ApiRoutes.getMakeContract.end_point,
                    params
                ).then((response) => {
                    // return false
                    if (response.resCode === 200) {
                        overlay(false);
                        scrollTotop();
                        // return false;
                        // dispatch({type: "SET_STATE", response: response, flash_message_show: true});
                        handleModal();
                        setCurrentStep(0);

                    } else {
                        overlay(false);
                        scrollTotop();
                        dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                    }
                }).catch(err => {
                    devLog('error', err)
                });
            } else {
                setOpenSigErrorModal(true);
                scrollTotop();
                dispatch({
                    type: 'SET_STATE',
                    response: { status: false, resCode: 400, data: null, message: 'Consenter Signature is required!' },
                    flash_message_show: true
                });
            }
        } else {
            setOpenSigErrorModal(true);
            setConsenteeVar(true);
            scrollTotop();
            dispatch({
                type: 'SET_STATE',
                response: { status: false, resCode: 400, data: null, message: 'Consentee Signature is required!' },
                flash_message_show: true
            });
        }
    }

    const handleSigSubmit = (event) => {
        event.preventDefault();
        const imageToSave = sigPad.getTrimmedCanvas()
            .toDataURL('image/png');
        setOpenSignatureModal(false);
        setFormData({ ...formData, party_2_signature: imageToSave })
    }

    const handleSigTwoSubmit = (event) => {
        event.preventDefault();
        const imageToSave = sigPad.getTrimmedCanvas()
            .toDataURL('image/png');
        setOpenSignatureTwoModal(false);
        setFormData({ ...formData, party_1_signature: imageToSave })
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
        // let form = {'username':username}
        const params = new FormData();
        params.append('signature_image', imageToSave);
        HttpRequest.makeRequest(
            ApiRoutes.updateUserProfile.method,
            ApiRoutes.updateUserProfile.end_point,
            params
        ).then((response) => {
            if (response.resCode === 200) {
                // overlay(false);
                // dispatch({type: "SET_STATE", response: response, flash_message_show: true});
                setStorageData(Constant.session_object, response.data);
                // setTimeout(() => {
                //     history.push('/profile')
                // }, 2000);
            } else {
                // overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
            }
        }).catch(err => {
            devLog('error', err)
        });

    }



    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, []);

    // flash message styling
    const flashMessageStyling = {
        width: '100%'
    }

    return (
        <>

            <form onSubmit={handleSubmit}>
                <FlashMessage cssStyles={flashMessageStyling} />
                < FromPreOne
                    data={formData}
                    changeHandler={handleChangeCheckBox}
                    currentStep={currentStep}
                />

                <FormOne
                    data={formData}
                    changeHandler={handleChangeCheckBox}
                    currentStep={currentStep}
                    handleChangePurpleBtn={handleChangePurpleBtn}
                />

                <FormTwo
                    data={formData}
                    changeHandler={handleChange}
                    currentStep={currentStep}
                />

                <FormFinal
                    data={formData}
                    changeHandler={handleChange}
                    currentStep={currentStep}
                    user={user}
                    handleSigModal={handleSigModal}
                    handleSigTwoModal={handleSigTwoModal}

                />
                {(screenWidth <= 768) ? (
                    <div className='row'>
                        {currentStep === 3 ? (
                            <div className='col-md-12'>
                                <Button
                                    className="hp-newagr-btn hp-mbresp-btn"
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </div>) : (
                            <div className={currentStep === 0 ? 'hp-mbresp-btn col-md-12 text-center' : 'col-md-12 text-center'} >
                                {nextButton()}
                            </div>
                        )}
                        <div className={'col-md-12 text-center back-btn'}>
                            {goBackButton()}
                        </div>

                    </div>
                ) : (
                    <div className='row'>
                        <div className={'col-md-6 '}>
                            {goBackButton()}
                        </div>

                        {currentStep === 3 ? (
                            <div className='col-md-6 text-right'>
                                <Button
                                    className="hp-newagr-btn"
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                >Submit</Button>
                            </div>
                        ) :
                            (
                                <div className={currentStep === 0 ? 'col-md-12 text-center' : 'col-md-6 text-right'}>
                                    {nextButton()}
                                </div>
                            )}
                    </div>
                )}


                {/* image signatureOne modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openSignatureModal}
                    onClose={handleSignatureModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openSignatureModal}>
                        <Box sx={style} className="modal-responsive">
                            <div className="container hmprofile-main-inner">
                                <div className='row'>
                                    <div className="col-md-12 hp-newagr-topheading">
                                        <h1 className='mt-0'>Edit Signature</h1>
                                    </div>
                                    <div className='col-md-12 '>
                                        <div className='hp-newagr-formcontent p-0 shadow-none'>
                                            {/* <form onSubmit={handleSigSubmit}>                    */}
                                            <div className='row'>
                                                {/* <FlashMessage cssStyles={flashMessageStyling}/> */}
                                                <div className="sig-container" style={{ 'height': '65vh' }}>
                                                    <div className="sig-sigContainer border">
                                                        <SignaturePad
                                                            canvasProps={{ className: "sig-sigPad" }}
                                                            clearOnResize={false}
                                                            ref={(ref) => { sigPad = ref }} />
                                                    </div>
                                                    <div className="row mt-5 px-3">
                                                        <div className='col-md-6 mb-3'>
                                                            <Button
                                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-clear-btn mx-1"
                                                                onClick={handleClearButton}
                                                                color="secondary"
                                                                variant="contained"
                                                            >
                                                                Clear
                                                            </Button>
                                                        </div>
                                                        <div className='col-md-6 text-right mb-3'>
                                                            <Button
                                                                onClick={handleSigSubmit}
                                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-save-btn mx-1"
                                                                color="secondary"
                                                                variant="contained"
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* </form>  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openSigErrorModal}
                    onClose={handleSigErrorModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openSigErrorModal}>
                        <Box sx={style} className="modal-responsive">
                            <img src="./assets/images/navbar-logo-iq.png" alt="" className="modal-img mt-2" />
                            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }} align="center" className="hp-newagr-modal-maincontent hpmodal-maincontent">
                    Consent can be withdrawn at Any Time.
                    </Typography> */}
                            <Typography id="transition-modal-description" sx={{ mt: 2, mb: 2 }} align="center" className="hp-newagr-modal-error">
                                Please insert {consenteeVar ? "Consentee" : "Consenter"} signature!
                            </Typography>
                            <Button
                                onClick={handleSigErrorModalClose}
                                className="hp-newagr-modal-btn secondmodal-special"
                                color="secondary"
                                variant="contained">I Understand</Button>
                        </Box>
                    </Fade>
                </Modal>

                {/* image signatureTwo modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openSignatureTwoModal}
                    onClose={handleSignatureTwoModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openSignatureTwoModal}>
                        <Box sx={style} className="modal-responsive">
                            <div className="container hmprofile-main-inner">
                                <div className='row'>
                                    <div className="col-md-12 hp-newagr-topheading">
                                        <h1 className='mt-0'>Edit Signature</h1>
                                    </div>
                                    <div className='col-md-12 '>
                                        <div className='hp-newagr-formcontent p-0 shadow-none'>
                                            {/* <form onSubmit={handleSigSubmit}>                     */}
                                            <div className='row'>
                                                {/* <FlashMessage />  */}
                                                <div className="sig-container" style={{ 'height': '65vh' }}>
                                                    <div className="sig-sigContainer border">
                                                        <SignaturePad
                                                            canvasProps={{ className: "sig-sigPad" }}
                                                            clearOnResize={false}
                                                            ref={(ref) => { sigPad = ref }} />
                                                    </div>
                                                    <div className="row mt-5 px-3">
                                                        <div className='col-md-6 mb-3'>
                                                            <Button
                                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-clear-btn mx-1"
                                                                onClick={handleClearButton}
                                                                color="secondary"
                                                                variant="contained"
                                                            >
                                                                Clear
                                                            </Button>
                                                        </div>
                                                        <div className='col-md-6 text-right mb-3'>
                                                            <Button
                                                                onClick={handleSigTwoSubmit}
                                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-save-btn mx-1"
                                                                color="secondary"
                                                                variant="contained"
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* </form>  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>

                {/* Error handler modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={errMsgModal}
                    onClose={handleErrMsgModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={errMsgModal}>
                        <Box sx={style} className="modal-responsive">
                            <img src="./assets/images/navbar-logo-iq.png" alt="" className="modal-img mt-3" />
                            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }} align="center" className="hp-newagr-modal-maincontent hpmodal-maincontent">
                    Consent can be withdrawn at Any Time.
                    </Typography> */}
                            <Typography id="transition-modal-description" sx={{ mt: 2, mb: 2 }} align="center" className="hp-newagr-modal-error">
                                Please select at least one option!
                            </Typography>
                            <Button
                                onClick={handleErrMsgModalClose}
                                className="hp-newagr-modal-btn secondmodal-special"
                                color="secondary"
                                variant="contained">I Understand</Button>
                        </Box>
                    </Fade>
                </Modal>
            </form>


        </>
    );
};

export default MainController;