import React from 'react';
import Navbar from '../Navbar/Navbar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ({ faqs }){

    return (
        < >
        <Navbar />
        <div className="hmprofile-main">
            <div className="container hmprofile-main-inner">
                <div className='row'>
                    <div className="col-md-12 hp-newagr-topheading">
                        <h1 className='mt-0'>FAQs</h1>
                    </div>
                    <div className='col-md-12 '>
                        <div className='hp-newagr-formcontent link-limit'>
                            {
                                faqs.length > 0 && (
                                    faqs.map(faq => (
                                        <Accordion className="mb-0 mt-0 faq-accor-con" key={faq.id}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            >
                                            <div className="faq-accor-title" dangerouslySetInnerHTML={{__html: faq.question}} /> 
                                            {/* <Typography className="faq-accor-title">{faq.question}</Typography> */}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            {/* <Typography className="faq-accor-summary"> */}
                                            <div className="faq-accor-summary" dangerouslySetInnerHTML={{__html: faq.answer}} /> 
                                            {/* </Typography> */}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                )
                            }
                            
                        </div>          
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}