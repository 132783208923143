// "use strict";

import Constants from '../utils/Constants';
import {getStorageData,removeStorageData} from '../utils/Helper';
const _ = require("lodash");

class HttpRequest
{
    static setHeaders()
    {
        //set header
        var header   = {};
        header.token = Constants.api_token;
        if (!_.isEmpty(localStorage.getItem(Constants.session_key))) {
            const api_token = getStorageData(Constants.session_key);
            header["Authorization"] = api_token;
        }
        return header;
    }


    static makeRequest(method,url,params)
    {
        
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: method,
                headers: new Headers(this.setHeaders()),
                body: params,
                mode:'cors'
            })
                .then((response) => response.json())
                .then((res) => {
                    if(res.resCode === 401){
                        removeStorageData();
                        return false;
                    }
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static makeQueryStringUrl(url, form_data)
    {
        const data = [...form_data.entries()];
        const queryStringParam = data
            .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
            .join("&");
        return url + queryStringParam;
    }
}
export default HttpRequest;
