import {useEffect, useState} from 'react';

export function useDebounceBase(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
}
export function useDebounce(initialValue = '', delay = 500) {
    const [Value, setValue] = useState(initialValue);
    const DebouncedValue = useDebounceBase(Value, delay);
    return [DebouncedValue, Value, setValue];
}