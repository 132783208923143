import React from 'react';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function TermsandConditions({ record }){
    const history = useHistory();
    const goBack = () => (history.goBack());
   
    return(
        <>
         <Navbar />
         <div className="hmprofile-main">
            <div className="container hmprofile-main-inner">
                <div className='row'>
                    <div className="col-md-12 hp-newagr-topheading">
                        <KeyboardBackspaceIcon onClick={goBack} className="mb-4 back-btn-style" style={{cursor: 'pointer'}}/>
                        <h1 className='mt-0 heading-backbtn'>Terms and Conditions</h1>
                    </div>
                    <div className='col-md-12 '>
                        <div className='hp-newagr-formcontent setting-resources link-limit'>
                            {
                                record && Object.keys(record).length > 0 &&
                                (<div dangerouslySetInnerHTML={{__html: record.content}} />)
                            }                         
                        </div>          
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};