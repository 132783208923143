import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import FlashMessage from '../FlashMessage/index';
import Constants from '../../utils/Constants';
import HttpRequest from '../../Services/HttpRequest';
import { Context } from '../../Store/store';
import ApiRoutes from '../../Services/ApiRoutes'; 


export default function EditSignature(){

    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    let sigPad = {};
    const handleClearButton = () => {
        sigPad.clear()
    }

      // update user handler
      const handleSubmit = (e) => {
        e.preventDefault();
        const imageToSave = sigPad.getTrimmedCanvas()
        .toDataURL('image/png');
        overlay(true);
        dispatch({ type: "SET_STATE",  response:[], flash_message_show: false});
        // let form = {'username':username}
        const params = new FormData();
        params.append('signature_image',imageToSave);
        HttpRequest.makeRequest(
            ApiRoutes.updateUserProfile.method,
            ApiRoutes.updateUserProfile.end_point,
            params
        ).then((response) => {
            if(response.resCode === 200){
                overlay(false);
                dispatch({type: "SET_STATE", response: response, flash_message_show: true});
                setStorageData(Constants.session_object, response.data);
                setTimeout(() => {
                    history.push('/profile')
                }, 2000);
            }else {
                overlay(false);
                dispatch({type: "SET_STATE", response: response, flash_message_show: true});
            }
        }).catch(err => {
            devLog('error',err)
        });
    }
    // display no flash message
    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
    }, []);

     // flash message styling
     const flashMessageStyling = {
        width: '100%'
    }
    
    return(
        <>
            <Helmet>
                <title>Consent - Edit Signature</title>
            </Helmet>
            <Navbar />
            <div className="hmprofile-main">
                <div className="container hmprofile-main-inner">
                    <div className='row'>
                        <div className="col-md-12 hp-newagr-topheading">                          
                            <h1 className='mt-0'>Edit Signature</h1>                            
                        </div>
                        <div className='col-md-12 '>
                            <div className='hp-newagr-formcontent shadow'>  
                            <form onSubmit={handleSubmit}>                   
                                <div className='row'>
                                    <FlashMessage cssStyles={flashMessageStyling}/>
                                    <div className="sig-container">
                                        <div className="sig-sigContainer border">
                                            <SignaturePad 
                                            canvasProps={{className: "sig-sigPad"}}
                                            clearOnResize={false}
                                            ref={(ref) => { sigPad = ref }} />
                                        </div>
                                        <div className="row mt-5 px-3">
                                            <div className='col-md-6 mb-3'>
                                                <p 
                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-clear-btn"                                                
                                                onClick={handleClearButton}
                                                >
                                                Clear
                                                </p>
                                            </div>
                                            <div className='col-md-6 text-right mb-3'>
                                                <button 
                                                type="submit" 
                                                className="hp-newagr-btn cpass-btn editsig-btn-special editsig-save-btn"                                                
                                                 >
                                                Save
                                                </button>
                                            </div>
                                        </div>
                                                                        
                                    </div>
                                </div>
                            </form> 
                            </div>          
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};