
import React from 'react';
import { Link } from 'react-router-dom';
import "react-phone-number-input/style.css";
import FlashMessage from '../FlashMessage/index';

export default function ForgotPassword( {submitForm} ){
    // const history = useHistory();
   // const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');

    // handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const params = new FormData(form);
        //let mobile_no = event.target.phoneNumber.value.split(' ');
        //const mobile_code = mobile_no[0];
        //mobile_no.shift();
        //params.delete('phoneNumberCountry');
        //params.delete('phoneNumber');
        //params.append('mobile_no',mobile_no.join(''));
        //params.append('mobile_code',mobile_code);
        submitForm(params);
    }
    return(
        <>
             <div className="rg-maincontainer">

                <main className="rg-form">
                    <form onSubmit={handleSubmit}>
                        <input  type="hidden" name="type" value="forget_password" />
                        <Link to="/home">
                            <img className="login-header-img fp-header-img login-header-modified"  src="./assets/images/newlogo.png" alt=" login header " width="72" height="57" />
                        </Link>
                        <h1 className="rg-form-heading fp-specialfont">Forgot Password</h1>
                        <p className="rg-form-para">Code will be sent to your email.</p>
                        <FlashMessage />
                        <div className=" hp-newagr-form phonenum-special mb-3">
                            <label htmlFor="outlined-adornment-password">Email</label>
                            <input name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                   className="rg-formcontrol" id="floatingInput"/>

                        </div>

                        <button className="rg-formbutton" type="submit">Send</button>

                    </form>
                </main>

             </div>
        </>
    );
};
