import React from 'react';
import { Helmet } from 'react-helmet-async';
import ResetPassword from './ResetPassword';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import { devLog, overlay, passwordInstruction, regexPassword } from '../../utils/Helper';
import { Context } from '../../Store/store';
import { useHistory } from 'react-router-dom';

const ResetPasswordLogic = (props) => {
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    const forgot_token = props && props.history && props.history.location && props.history.location.state.forgot_token;

    // handle submit
    const formSubmit = (event) => {
        event.preventDefault();
        const newPass = event.target.new_password.value;
        const confirmPass = event.target.confirmPassword.value;

        if( newPass === "" || confirmPass === ""){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
            });
        }
        else if ( newPass !== confirmPass ){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'new password and confirm password mismatch'}, 
                flash_message_show: true});
        }
        else{
            if( regexPassword.test(event.target.new_password.value)){
            apiCallFunction(event);
            } else {
                dispatch({
                    type: 'SET_STATE', 
                    response:{status: false, resCode: 400, data: null,message: passwordInstruction}, 
                    flash_message_show: true
                }); 
            }
        }
    }

  // sumbit function apicall
  const apiCallFunction = (event) => {
    const form = event.target;
    let params = new FormData(form);
    params.delete('confirmPassword');
    params.append('forget_token',forgot_token);
    overlay(true);
    dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
    HttpRequest.makeRequest(
        ApiRoutes.loginResetPass.method,
        ApiRoutes.loginResetPass.end_point,
        params
    ).then((response) => {
        if (response.resCode === 200){
            overlay(false);
            dispatch({ 
                type: "SET_STATE", 
                response: response, 
                flash_message_show: true
            });
            setTimeout(() => {
                history.push('/login')
            }, 1000);
        }else{
            dispatch({ 
                type: "SET_STATE", 
                response:response, 
                flash_message_show: true
            });
            overlay(false);
        }
    }).catch(err => {
        devLog("error", err);
    });
  }

  React.useEffect(()=>{
    dispatch({
        type:'SET_STATE',
        response:[], 
        flash_message_show:false
    });
},[]);

    return(
        <>
            <Helmet>
                <title>Consent - Reset Password</title>
            </Helmet>
            <ResetPassword formSubmit={formSubmit}  />
        </>
    );
};

export default ResetPasswordLogic;