import React from 'react';

const FromPreOne = ({ data, changeHandler, currentStep }) => {
    return(
        <>
        {
        currentStep == 0 ? (<>
            <p className="hp-newagr-content-p">I Consent to:</p>
            { data.consents.length > 0 && (data.consents.map((el) => {
                return(
                    <div key={el.id}>
                    <div className='d-flex justify-content-between'>
                        <div className='hp-natoggle-content'>
                            <span className="dot">
                                <img src={el.image_url} alt="logout icon " />
                            </span>
                            <p className="agreeoptions-typo">{el.name}</p>
                        </div>
                        <div className='hp-natoggle-btn agreeoption-toggle' >
                            <label className="switch mb-0">
                            <input 
                                type="checkbox" 
                                name={el.name} 
                                // value={el.value} 
                                checked={el.value}
                                onChange={(event)=>changeHandler({id:el.id,'formConsent':'consent'},event)} 
                            />
                            <span className="hpnatoggle-slider round">
                            </span>
                            </label>
                        </div>
                    </div>
                    <hr />
                    </div>
               
                )
            })
            )
            }
            
        </>): "" }
        </>
    );
};


export default FromPreOne;