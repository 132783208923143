import React from 'react';


const FormFinal = ({data, changeHandler, currentStep, user, handleSigModal, handleSigTwoModal}) => {

    return (
        <>
            {
                currentStep == 3 ? (
                    <>
                        {/* <h5 className='hp-postagree-heading'>
                            Contract Information
                          </h5> */}
                        <p className='hp-postagree-p'>
                            I, <span>{data.party_1_name}</span>, hereby declare my intentions to voluntarily engage in
                            romantic relations
                            with <span>{data.party_2_name}</span> on <span>{data.agreement_date}</span> at <span>{data.agreement_time}</span>.
                        </p>
                        <p className='hp-postagree-p'>We hereby certify:</p>
                        <ol className='hp-postagree-p'>
                            <li key={1}>We are each at least 18 years old</li>
                            <li key={2}>Neither of us are intoxicated</li>
                            <li key={3}>Neither of us is under duress</li>
                            <li key={4}>We have and will not exchange goods, monies, services, or any payment</li>
                            <li key={5}>We will not distribute nor communicate in any fashion the existence of this
                                agreement to anyone but ourselves, law enforcement, or lawyer*
                            </li>
                            <li key={6}>To the best of each of our knowledge neither of use has any sexually transmitted
                                diseases
                            </li>
                            <li key={7}>We understand that this agreement can be revoked at any time</li>
                        </ol>

                        <p className='hp-postagree-p'>
                            We agree to the following acts:
                        </p>
                        <ul className={data && data.agreed_option.length > 0 ? 'hp-postagree-list' : "hp-postagree-list pl-0"}>
                            {
                                data && data.agreed_option.length > 0 ? (data.consents.map(function (el, index) {
                                            return (
                                                el.value &&
                                                (
                                                    <li key={index}>
                                                        {el.name}
                                                    </li>

                                                )
                                            );
                                        })
                                    ) :
                                    <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p>

                            }
                        </ul>

                        <p className='hp-postagree-p'>
                            We agree to the following restrictions:
                        </p>
                        <ul className={data && data.disagreed_option.length > 0 ? "hp-postagree-list" : "hp-postagree-list pl-0"}>
                            {
                                data && data.disagreed_option.length > 0 ? (data.restrictions.map(function (el, index) {

                                            return (
                                                el.value &&
                                                (
                                                    <li key={index}>
                                                        {el.name}
                                                    </li>
                                                )
                                            );
                                        })
                                    ) :
                                    // <p>{Object.keys(data.disagreed_option).length}</p>
                                    <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p>
                            }
                        </ul>

                        <p className='hp-postagree-p'>Additional Provisions that we agree to:</p>
                        <textarea
                            rows="6"
                            className='contactus-textarea mt-2 w-100 formfinal-ta'
                            placeholder="Add your Safe Word here if applicable with any other provisions"
                            value={data.additional_text}
                            name="additional_text"
                            onChange={changeHandler}
                            // type="textarea"
                        />
                        {/* <p className='hp-newagr-specialpoints-beforeowner hp-postagree-list'>Lorem ipsum dolor sit amen cons</p> */}
                        <div className="hp-newagr-pastagr-sig">
                            <p className='hp-newagr-specialpoints-owner'>{data.party_1_name}</p>
                            <div className="hp-newagr-pastagr-sigouter">
                                {data.party_1_signature == null ?
                                    <div className="sig-modal" style={{'cursor': 'pointer'}}
                                         onClick={handleSigTwoModal}></div> : <img src={data.party_1_signature} style={{
                                        'cursor': 'pointer',
                                        'maxHeight': '400px'
                                    }} alt="Consentee Signature"/>}
                            </div>
                        </div>
                        <div className="hp-newagr-pastagr-sig mt-4">
                            <p className='hp-newagr-specialpoints-owner'>{data.party_2_name}</p>
                            <div className="hp-newagr-pastagr-sigouter">
                                {data.party_2_signature == "" ? <div className="sig-modal" style={{'cursor': 'pointer'}}
                                                                     onClick={handleSigModal}></div> :
                                    <img onClick={handleSigModal} style={{'cursor': 'pointer', 'maxHeight': '400px'}}
                                         src={data.party_2_signature} alt="Consenter Signature"/>}
                            </div>
                        </div>
                        {/* <p className='hp-postagree-p font-theme-color mt-4'>*To the best of each individuals knowledge, we are clean of sexually transmitted diseases and other infections.</p> */}
                        {
                            data && data.abiderules.length > 0 && (
                                <p className='hp-postagree-p font-theme-color  mt-4'
                                   dangerouslySetInnerHTML={{__html: data.abiderules[0].text}}/>
                            )
                            // <p>{Object.keys(data.disagreed_option).length}</p>
                            // <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p>
                        }

                        {/* *Failure to abide by this provision may be considered criminal in some jurisdictions and the aggrieved party will have grounds to sue for inter alia Breach of Contract, Intentional Infliction of Emotional Distress, and/or False Light. */}
                        {/* <div className="faq-accor-title"  />  */}
                        {/* <div className='text-center'>
                                <button className="hp-newagr-btn" style={{'marginBottom': '70px'}}>Submit</button>
                          </div> */}
                    </>
                ) : ""
            }

        </>
    );
};

export default FormFinal;