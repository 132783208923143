import React from 'react';
import { Helmet } from 'react-helmet-async';
import AProcedure from './AProcedures';
import { Context } from '../../Store/store';
import { devLog, overlay } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes'; 

const AProceduresLogic = () => {

    const [state, dispatch] = React.useContext(Context);
    const [record, setRecord] = React.useState([]);

    React.useEffect(() => {
        dispatch({ type: "USE_STATE", response: [], flash_message_show: false });
        overlay(true);
        HttpRequest.makeRequest(
            ApiRoutes.getAppContents.method,
            `${ApiRoutes.getAppContents.end_point}?identifier=Arbitration Procedures`
        )
            .then((response) => {
                if (response.resCode === 200) {
                    overlay(false);
                    setRecord(response.data)
                } else {
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                }
            })
            .catch(err => {
                devLog('Error', err);
            });

    }, []);
    return (
        <>
            <Helmet>
                <title>Consent - Arbitration Procedures</title>
            </Helmet>
            <AProcedure record={record} />
        </>
    );
}

export default React.memo(AProceduresLogic);