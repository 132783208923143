import React, {useRef, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import {Link, useHistory} from 'react-router-dom';
import { Context } from '../../Store/store';
import { devLog, overlay } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import FlashMessage from '../FlashMessage/index';
import ApiRoutes from '../../Services/ApiRoutes';


export default function NAVerificationCode({ handleResend,submitForm }){

    const history = useHistory();
    const [code,setCode] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        let params = new FormData();
        params.append("type","forget_password")
        params.append("code",code)
        submitForm(params);
    }


    return(
        <>
            <Helmet>
                <title>Consent - Verification Code</title>
            </Helmet>
            <div className="rg-maincontainer">

                <main className="rg-form">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value="forget_password" name="type" />
                        <Link to="/home">
                            <img 
                                className="login-header-img fp-header-img" 
                                src="./assets/images/loginheader.png" 
                                alt=" Main Logo " 
                                width="72" 
                                height="57"
                            />
                        </Link>
                        <FlashMessage />
                        <h1 className="rg-form-heading fp-specialfont">Verification Code</h1>
                        <p className="rg-form-para">We have sent you a 6 Digit Verification Code
                            On your email.</p>

                        <div className="rg-form-floating">
                            <label htmlFor="floatingInput">Please enter the code to Proceed.</label>
                            <input 
                                type="text" 
                                className="rg-formcontrol rg-mbpass" 
                                id="floatingInput"
                                onChange={(evt)=>setCode(evt.target.value)}
                                name="code" 
                            />
                            <p className="rc-resendcode ">Didn’t get the Code? <button className="rg-formbutton" type="button" onClick={()=>{handleResend()}}> Resend Code</button> </p>
                        </div>
                        
                        <button className="rg-formbutton" type="submit">Submit</button>
                        

                    </form>
                </main>   

            </div>
        </>
    );
    };