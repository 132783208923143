import React, {useState, useEffect, useContext} from 'react';
import { NavLink, Link } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { getStorageData } from '../../utils/Helper';
import Constants from '../../utils/Constants';
import { Context } from '../../Store/store';


export default function Navbar({match,location}) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [state, dispatch] = useContext(Context);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const user = getStorageData(Constants.session_object);
  // const [homeButton, setHomeButton] = useState({'home':false,'faq':false,'setting':false,'contactus':false});
  // const [isHomeActive, setIsHomeActive] = useState(false);
  // const [isFAQActive, setIsFAQActive] = useState(false);
  // const [isSettingActive, setIsSettingActive] = useState(false);
  // const [isContactusActive, setIsContactusActive] = useState(false);
  // const [faqButton, setFaqButton] = useState(false);
  // const [settingButton, setSettingButton] = useState(false);
  // const [contactusButton, setContactusButton] = useState(false);
  // const urlPath = window.location.pathname;
  // let isHomeActive = false;
  // let isFAQActive = false;
  // let isSettingActive = false;
  // let isContactusActive = false;

  // const clickNavItem = (e) => {
  //   if(urlPath == "/home"){
  //     setHomeButton({...homeButton,'home':true,'faq':false,'setting':false,'contactus':false});
  //     } else if (urlPath == "/faq"){
  //       setHomeButton({...homeButton,'home':false,'faq':true,'setting':false,'contactus':false});
  //   }else if ( urlPath == "/setting" ){
  //     setHomeButton({...homeButton,'home':false,'faq':false,'setting':true,'contactus':false});
  //   } else if ( urlPath == "/contactus" ) {
  //     setHomeButton({...homeButton,'home':false,'faq':false,'setting':false,'contactus':true});
  //   }
  // }


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      dispatch({
        type: "UPDATE_SCREENWIDTH",
        response: {"screenWidth": window.innerWidth}
      });
      // setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])
    let isHomeActive  = false;
    let isFAQActive = false;
    let isSettingActive = false;
    // let isContactusActive = false;
    const settingUrlCheck = window.location.pathname == `/setting` || window.location.pathname == `/resources` || window.location.pathname == `/termsconds` || window.location.pathname == `/privacypolicy` || window.location.pathname == `/aprocedures`;
    if (window.location.pathname == `/home`) {
      isHomeActive = true;
    } else if ( window.location.pathname == `/faq` ){
      isFAQActive = true;
    } else if ( settingUrlCheck ){
      isSettingActive = true;
    }
    // else if ( window.location.pathname == `/contactus` ){
    //   isContactusActive = true;
    // }

  // console.log(isHomeActive)
  // const homeEvent = (match, location)=>{
  //     if(match){
  //       //set isActive state true
  //       isHomeActive = true;
  //     }
  //     return match;
  //   }
  // const faqEvent = (match, location)=>{
  //   if(match){
  //     //set isActive state true
  //     isFAQActive = true;
  //   }
  //   return match;
  // }

  // const settingEvent = (match, location)=>{
  //   if(match){
  //     //set isActive state true
  //     isSettingActive = true;
  //   }
  //   return match;
  // }

  // const contactusEvent = (match, location)=>{
  //   if(match){
  //     //set isActive state true
  //     isContactusActive = true;
  //   }
  //   return match;
  // }
  console.log('user:', user)
  return (
    <>
    <div className="main-navbar">
    {(toggleMenu || state.screenWidth > 768)&& (
        <div className="inner-nav-con display-f justify-c-sb">
            <div className="navbar-left-menu display-f aligni-center">
                <div className="nav-logoicon-box">
                    <Link to="/home" >
                      <img src="./assets/images/navbar-logo.png" alt="Navbar logo image" />
                    </Link>
                </div>
                {/* <div className="nav-searchbar">
                    <input type="text" className="navbar-search" placeholder="Search" />              
                </div> */}
            </div>
            <div className="navbar-center-menu mb-0 main-menu-resp">
                <ul className="menu-flex mb-0">
                    <li className=" ">
                      <NavLink to="/home"
                      className={isHomeActive ? "navBorderBottom" : ""}
                      >
                            <img src={isHomeActive ? "./assets/images/home-icon-colored.png" : "./assets/images/home-icon.png"} alt="home icon" />
                      </NavLink>
                    </li>
                    <li className=" navbar-item">
                      <NavLink to="/faq"
                      className={isFAQActive ? "navBorderBottom" : ""}
                      // isActive={faqEvent}
                      >
                        <img src={ isFAQActive ? "./assets/images/faq-icon-colored.png": "./assets/images/faq-icon.png" } alt="faq icon" />
                      </NavLink>
                    </li>
                    <li className=" navbar-item">
                      <NavLink to="/setting"
                       className={isSettingActive ? "navBorderBottom" : ""}
                      // isActive={settingEvent }
                      >
                        <img src={ isSettingActive ? "./assets/images/setting-icon-colored.png" : "./assets/images/setting-icon.png"} alt="setting icon" />
                      </NavLink>
                    </li>
                    {/* <li className=" navbar-item">
                      <NavLink to="/contactus" 
                       className={isContactusActive ? "navBorderBottom" : ""}
                      // isActive={ contactusEvent }
                      >
                        <img src={ isContactusActive ? "./assets/images/contactus-icon-colored.png" : "./assets/images/contactus-icon.png" }  alt="contactus icon" />
                      </NavLink>
                    </li> */}
                </ul>
            </div>
            <div className="navbar-right-menu display-f aligni-center">
                <div className="profile-name ml-right-tw" >
                    <p className='mb-0 profile-name-p'>Welcome <Link to="/profile">{user && user.username}!</Link></p>
                </div>
                {/*<div className="profile-img ml-right-tw">*/}
                {/*    <Link to="/profile">*/}
                {/*      <img src={(user && user.image_url) ? user.image_url : ".assets/images/avatar-img.jpg"} alt="profile image" />*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/* <div className="notification-icon ml-right-tw"> */}
                    {/* <img src="./assets/images/user-image.png" alt="notification icon" /> */}
                    {/* <NotificationsNoneIcon /> */}
                {/* </div>                 */}
            </div>

        </div>)}
        {/* <button onClick={toggleNav} className="btn-toggle">  */}
        <div className='row'>
          <div className='col-md-1 col-1'>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleNav} className="btn-toggle"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className='col-md-11 col-11  mw-special'>
                  {( state.screenWidth <= 768)&& ( <div className="navbar-center-menu mb-0 text-center navbar-sdevice">
                  <ul className="menu-flex mb-0">

                      <li className=" ">
                        <NavLink to="/home"
                        className={isHomeActive ? "navBorderBottom" : ""}
                        >
                              <img src={isHomeActive ? "./assets/images/home-icon-colored.png" : "./assets/images/home-icon.png"} alt="home icon" />
                        </NavLink>
                      </li>
                      <li className=" navbar-item">
                        <NavLink to="/faq"
                        className={isFAQActive ? "navBorderBottom" : ""}
                        // isActive={faqEvent}
                        >
                          <img src={ isFAQActive ? "./assets/images/faq-icon-colored.png": "./assets/images/faq-icon.png" } alt="faq icon" />
                        </NavLink>
                      </li>
                      <li className=" navbar-item">
                        <NavLink to="/setting"
                          className={isSettingActive ? "navBorderBottom" : ""}
                        // isActive={settingEvent }
                        >
                          <img src={ isSettingActive ? "./assets/images/setting-icon-colored.png" : "./assets/images/setting-icon.png"} alt="setting icon" />
                        </NavLink>
                      </li>
                      {/* <li className=" navbar-item">
                        <NavLink to="/contactus" 
                          className={isContactusActive ? "navBorderBottom" : ""}
                        // isActive={ contactusEvent }
                        >
                          <img src={ isContactusActive ? "./assets/images/contactus-icon-colored.png" : "./assets/images/contactus-icon.png" }  alt="contactus icon" />
                        </NavLink>
                      </li> */}
                  </ul>
              </div>)}
          </div>
        </div>

      {/* </button> */}

    </div>


    </>
  )
}
