import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PublicRoutes from "./Routes/PublicRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";
import Constants from './utils/Constants';
import {getStorageData,devLog} from './utils/Helper';
import Store from './Store/store';
const _ = require("lodash");



function App() { 
    if (!_.isEmpty(localStorage.getItem(Constants.session_object))) {
        const userObj = getStorageData(Constants.session_object);
        devLog("userObj", userObj);
        var isAuth = true;
    } else {
        isAuth = false;
    }
    return (
        <Store>
            <Router>                
                {!isAuth && <PublicRoutes />}
                {isAuth && <PrivateRoutes />}
            </Router>
        </Store>
    );
}

export default App;