"use strict";

module.exports = {

     //base_path: 'http://theconsentwebapp.qa.retrocubedev.com/',
    //base_path: 'http://localhost:3000',
    // base_path: 'http://localhost:8002',
    base_path: '//web.theconsent.com',
    base_path_web: '/',
    default_language: 'en',

    api_token: 'api.Pd*!(5675',

    session_key: "SESSION_KEY",

    session_object: "SESSION_OBJECT",
    baseURLive: '',
    // baseURLLocal: 'http://69.162.81.82:8002/',
    baseURLLocal: '//theconsent.com:8002/',
    //baseURLLocal: 'http://127.0.0.1:8002/',
    pagination_size: 20,
    MIX_STRIPE_SANDBOX_KEY: 'pk_test_51L6dttKo2VoGTQgu0CigqukKwwRwurAuoMC4rdHmFNUdn6o1UO1iceCmHvkLjfkfDanovZCVaAJz1eOdOFlcjdrb00C568h4Nt',
    // CLIENT_ID: '179690172739-5g9l2c6dgutc9j6mdp6egcds9um8alr0.apps.googleusercontent.com',
    CLIENT_ID: '762620279212-q9cumr1fa5hif9s3pcg2uvla1kbmkbo7.apps.googleusercontent.com',
    // FB_APP_ID: "539888507926975",
    FB_APP_ID: "674307281083094",


}
