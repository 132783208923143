import { Redirect } from "react-router-dom";
import Constants from "../../../utils/Constants";
import {getStorageData} from "../../../utils/Helper";


const NotFound = () => {
  const user = getStorageData(Constants.session_object);
  if (user) {
    return <Redirect to={"/home"} exact />;
  } else {
    return <Redirect to={"/"} exact />;
  }
};
export default NotFound;
