import React from 'react';


const ListingPA = ( {record, handleViewAgreement } ) => {
    const viewAgreementHandler = (event, id) => {
        handleViewAgreement(event, id);
    }
    return(
       <>
       {/* <Helmet>
        <title>Consent - Past Agreements</title>
       </Helmet> */}
       {
           record && record.length > 0 ? 
           (record.map(el =>{
             return (                 
                 <div className='row p-0 pastagree-aftertopbar-con mb-4' key={el.id}>
                   <div className='col-md-6 p-0 '>
                     <div className='pastagree-aftertopbar pa-brspecial' onClick={e =>viewAgreementHandler(e,el.id)}>
                       <p className='pa-content-aftertopbar-para'>Consenter</p>
                       <p className="pa-content-aftertopbar-paraname">{el.party_2_name}</p>
                     </div>
                     <div className='d-flex justify-content-between pastagree-datetime'>
                       <div className='pastagree-content'>
                         <img src="./assets/images/calendar-icon.png" alt="calendar icon" />
                         <p >{el.agreement_date}</p>
                       </div>
                       <div className='pastagree-content'>
                         <img src="./assets/images/time-icon.png" alt="clock icon" />
                         <p>{el.agreement_time}</p>
                       </div>
                     </div>
                   </div>
                   <div className='col-md-6 p-0'>
                     <div className='pastagree-aftertopbar pa-bg-color' onClick={e =>viewAgreementHandler(e,el.id)}>
                       <p className='pa-content-aftertopbar-para'>Consentee </p>
                       <p className="pa-content-aftertopbar-paraname">{el.party_1_name}</p>
                     </div>
                     <div className='d-flex justify-content-between pastagree-datetime'>
                       <div className='pastagree-content'>
                         <img src="./assets/images/calendar-icon.png" alt="calendar icon"/>
                         <p >{el.agreement_date}</p>
                       </div>
                       <div className='pastagree-content'>
                         <img src="./assets/images/time-icon.png" alt="clock icon" />
                         <p>{el.agreement_time}</p>
                       </div>
                     </div>
                   </div>
                 </div>
              
            )
        }
           
           ) ): 
           (<div className="text-center ">
               <p className="font-weight-bold text-uppercase">Data not found!</p>
           </div>)
       }
       </>
    );
}

export default React.memo(ListingPA);