import React, {useEffect, useState} from 'react';
import {Context} from '../../Store/store';
import {overlay, devLog, getStorageData} from '../../utils/Helper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Constant from '../../utils/Constants';
import FlashMessage from '../FlashMessage';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import Constants from '../../utils/Constants';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const PurchaseHistory = ({goBack}) => {

    const [state, dispatch] = React.useContext(Context);
    const [isLoading, setIsLoading] = React.useState(true);
    const [record, setRecord] = useState(undefined);

    useEffect(() => {
        dispatch({type: "USE_STATE", response: [], flash_message_show: false});
        overlay(true);
        HttpRequest.makeRequest(
            ApiRoutes.getSubscriptionHistory.method,
            ApiRoutes.getSubscriptionHistory.end_point
        )
            .then((response) => {
                if (response.resCode == 200) {
                    overlay(false);
                    setIsLoading(false);
                    setRecord(response.data);
                    // dispatch({type:"SET_STATE", response:response, flash_message_show: true});
                } else {
                    //   overlay(false);
                    overlay(false);
                    setIsLoading(false);
                    //   dispatch({type:"SET_STATE", response:response, flash_message_show: true});
                }
            })
            .catch(err => {
                devLog('error', err);
            });
        // }
    }, []);

    return (
        <>

            <KeyboardBackspaceIcon onClick={goBack} className="mb-4" style={{cursor: 'pointer'}}/>

            <div className='row'>
                {/* <FlashMessage /> */}
                {
                    isLoading && (
                        <Box sx={{display: 'flex'}} className="mx-auto">
                            <CircularProgress/>
                        </Box>
                    )
                }
                {!isLoading && (record && record.length > 0 ? (
                    record.map(el => {
                        return (
                            <div className="col-md-12" key={el.id}>
                                <div
                                    className={`hp-subs-disc-box ${el.package_status === "active" && "hp-subs-disc-bg"}`}
                                    style={{'cursor': 'inherit'}}>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div>
                                                <p className="hp-subsdisc-pname">{el.title}</p>
                                                {(el.discount_amount > 0) ?
                                                    (<p>real price <del>${el.price}</del> and
                                                        after {el.discount} discount
                                                        apply
                                                    </p>) : ("")}

                                                <p className="hp-subsdisc-ptimedate">{el.purchase_date}
                                                    <span> |</span> {el.expiry_date}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            {(el.discount_amount > 0) ?
                                                (<h1>${el.price - el.discount_amount}</h1>) : (
                                                    <h1>${el.price}</h1>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className="mx-auto"><h5>Data Not found!</h5></div>
                ))}

                {/* <div className="col-md-12" >
                        <div className=' hp-subs-disc-box'>
                            <div className='row'>
                            <div className='col-md-6'>
                                <div >
                                <p className="hp-subsdisc-pname">Yearly Package</p>
                                <p className="hp-subsdisc-ptimedate">14 Aug, 2020 <span>|</span> 01:00 PM</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <h1>$0.99</h1>
                            </div>
                            </div>
                        </div>
                    </div>  
                    <div className="col-md-12" >
                        <div className= ' hp-subs-disc-box'>
                            <div className='row'>
                            <div className='col-md-6'>
                                <div >
                                <p className="hp-subsdisc-pname">Yearly Package</p>
                                <p className="hp-subsdisc-ptimedate">14 Aug, 2020 <span>|</span> 01:00 PM</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <h1>$0.99</h1>
                            </div>
                            </div>
                        </div>
                    </div>                         */}
            </div>


        </>
    );
};

export default PurchaseHistory;