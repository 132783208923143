import React from 'react';
import { Helmet } from 'react-helmet-async';
import Login from './Login';
import { useHistory } from 'react-router-dom';
import { Context } from '../../Store/store';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Constants from '../../utils/Constants';
const _ = require('lodash');

export default function LoginLogic() {

    const [state, dispatch] = React.useContext(Context);
    const deviceToken = 123456789;
    const device_type = 'web';
    const history = useHistory();
    
    const submitForm = (event,email,pass) => {
        if(email !== "" && pass !== ""){
            dispatch({
                type: "SET_STATE", 
                response: [], 
                flash_message_show: false 
            });
            overlay(true);
            // const form = event.target;
            const params = new FormData();
            params.append('email',email);
            params.append('password',pass);
            params.append('device_type',device_type);
            params.append('device_token', deviceToken);
            HttpRequest.makeRequest(
                ApiRoutes.loginUser.method,
                ApiRoutes.loginUser.end_point,
                params
            )
            .then((response) => {
                if(response.resCode === 200){
                    overlay(false);
                    dispatch({
                        type:"SET_STATE",                     
                        response:response, 
                        flash_message_show: true
                    });
                    setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                    setStorageData(Constants.session_object, response.data);
                    setTimeout(() => {
                        window.location.href=Constants.base_path + `/home`
                    }, 1000);
                }
                // else if(response.data !== null && response.data.errorCodeType === "402") {
                //     setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                //     dispatch({
                //         type:"SET_STATE", 
                //         response: response, 
                //         flash_message_show: true
                //     });
                //     overlay(false);
                //     setTimeout(() => {
                //         history.push("/verification-code") 
                //     },  3000); 
                // }
                // else if(response.data !== null && response.data.errorCodeType === "409") {
                //     setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                //     dispatch({
                //         type:"SET_STATE", 
                //         response: response, 
                //         flash_message_show: true
                //     });
                //     overlay(false);
                //     setTimeout(() => {
                //         history.push("/change-phone-number-login") 
                //     },  3000); 
                // }
                else{
                    dispatch({
                        type:"SET_STATE", 
                        response: response, 
                        flash_message_show: true
                    });
                    overlay(false);
                }
            })
            .catch(err => {
                devLog('error', err);
            });

        }else {
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
                })
        }
    };

    const handleGoogleLogin = async (response) => {
        // console.log("google-response:",response);
        // return false;
        if (!_.isEmpty(response)) {
                overlay(true)
                let params = new FormData();
                // params.append('token',response);
                // params.append("loginMethodGoogle", true);
                params.append("email", response.email);
                params.append("username", response.name);
                params.append("social_id", response.sub);
                params.append("platform_type", "google");
                params.append("device_type", "web");
                params.append("device_token", deviceToken);
                // params.append("latitude", "0");
                // params.append("longitude", "0");
                params.append("image_url", response.picture);
                HttpRequest.makeRequest(
                    ApiRoutes.socialLogin.method,
                    ApiRoutes.socialLogin.end_point, 
                    params
                ).then((response) => {
                    if(response.resCode === 200){
                        overlay(false);
                        dispatch({
                            type:"SET_STATE",                     
                            response:response, 
                            flash_message_show: true
                        });
                        setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                        setStorageData(Constants.session_object, response.data);
                        setTimeout(() => {
                            window.location.href=Constants.base_path + `/home`
                        }, 1000);
                    }else {
                        dispatch({
                            type:"SET_STATE", 
                            response: response, 
                            flash_message_show: true
                        });
                        overlay(false);
                    }
                }).catch(err => devLog('Error',err));
            }
        };

    const handleFacebookLogin = async (response) => {
        // console.log("facebook-response:",response);
        // return false;
        if (_.isEmpty(response.status)) {
            // console.log('res::',response.status)
                overlay(true);
                let params = new FormData();
                // params.append("user_group_id", "3");
                params.append("email", response.email);
                params.append("username", response.name);
                params.append("social_id", response.id);
                params.append("platform_type", response.graphDomain);
                // params.append("device_type", "web");
                params.append("device_token", deviceToken);
                params.append("latitude", "0");
                params.append("longitude", "0");
                params.append("image_url", response.picture.data.url);
                // console.log('url', ApiRoutes.socialLogin.url)
                HttpRequest.makeRequest(
                    ApiRoutes.socialLogin.method,
                    ApiRoutes.socialLogin.end_point, 
                    params
                ).then((response) => {
                    if(response.resCode === 200){
                        overlay(false);
                        dispatch({
                            type:"SET_STATE",                     
                            response:response, 
                            flash_message_show: true
                        });
                        setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                        setStorageData(Constants.session_object, response.data);
                        setTimeout(() => {
                            window.location.href=Constants.base_path + `/home`
                        }, 1000);
                    }else {
                        dispatch({
                            type:"SET_STATE", 
                            response: response, 
                            flash_message_show: true
                        });
                        overlay(false);
                    }
                }).catch(err => devLog('Error',err));
            }
            overlay(false);
    };

    React.useEffect(() => {
        dispatch({
            type: "SET_STATE", 
            response:[], 
            flash_message_show: false 
        });
    }, []);

    return(
        <>
            <Helmet>
                <title>Consent - Login</title>
            </Helmet>
            <GoogleOAuthProvider clientId={Constants.CLIENT_ID}>
                <Login 
                submitForm={submitForm}
                handleGoogleLogin={handleGoogleLogin}
                handleFacebookLogin={handleFacebookLogin}
                deviceToken={deviceToken}
                />
            </GoogleOAuthProvider>
        </>
    );
};