import * as React from 'react';
import Stripe from '../Subscription/Stripe';
import { Helmet } from 'react-helmet-async';
import ListingPA from './ListingPA';
import PAPinVerification from './PAPinVerification';
import PropTypes from 'prop-types';
import Navbar from '../Navbar/Navbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import MainController from '../FormWizardNewAgr/MainController';
import FormWizardPA from './FormWizardPA';
import SubscriptionPackages from './SubscriptionPackages';
import { Context } from '../../Store/store';
import { overlay, devLog, getStorageData } from '../../utils/Helper';
import { Link } from 'react-router-dom';
import Constant from '../../utils/Constants';
import FlashMessage from '../FlashMessage';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import moment from 'moment';
import PAFilter from './PAFilter';
import Constants from '../../utils/Constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,

  bgcolor: 'background.paper',
  p: 4,
  textAlign: 'center',
  padding: '50px 40px 50px 40px',
  borderRadius: '10px',
  boxShadow: '0 2px 6px 0 rgb(0 0 0 / 10%)',
  backgroundColor: '#fff',
  border: '1px solid transparent',
  outline: 'none'

}


export default function Home() {
  // const [value, setValue] = React.useState(0);
  const [state, dispatch] = React.useContext(Context);
  const [tabVar, setTabVar] = React.useState(false);
  const [agreements, setAgreements] = React.useState(true);
  const [pastagr, setPastAgr] = React.useState(false);
  const isInitialMount = React.useRef(true);
  const [subscription, setSubscription] = React.useState(false);
  const [packageData, setPackageData] = React.useState([]);
  const [apiControllingPA, setApiControllingPA] = React.useState(false);
  const [pastAgrRecord, setPastAgrRecord] = React.useState(undefined);
  const [detailedRecordID, setDetailedRecordID] = React.useState("");
  const [detailedRecord, setDetailedRecord] = React.useState({});
  const [searchControllVar, setSearchControllVar] = React.useState(false);
  const [searchFormData, setSearchFormData] = React.useState({
    dateFrom: "",
    dateTo: "",
    partyName: ""
  });
  const [stripeShow, setStripeShow] = React.useState(false);


  // pa pin controller
  const [pinCodeController, setPinCodeController] = React.useState(false);
  const userObj = getStorageData(Constants.session_object);

  const [openFirstModal, setOpenFirstModal] = React.useState(false);
  const [openInfoModal, setOpenInfoModal] = React.useState(false);

  const [openSecondModal, setOpenSecondModal] = React.useState(false);

  const [subsDuration, setSubsDuration] = React.useState('month');
  const [coupon, setCoupon] = React.useState('');
  const [subsDurationDisc, setSubsDurationDisc] = React.useState('one');

  const [viewAgreement, setViewAgreement] = React.useState(false);
  const [isPackageView, setIsPackageView] = React.useState(false);
  const [isPurchaseHistory, setIsPurchaseHistory] = React.useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = React.useState(false);

  const [isSubsValid, setIsSubsValid] = React.useState(false);

  //handle stripe modal 
  const handleModalClose = () => {
    setStripeShow(false);
  };
  const searchParamChange = (event) => {
    setSearchFormData({ ...searchFormData, [event.target.name]: event.target.value })
  }
  const handleModal = (controlVar = false) => {
    setOpenFirstModal(true);
  };

  const handleFirstModalClose = () => {
    setOpenFirstModal(false);
    setOpenSecondModal(true);
  }

  const handleSecondModalClose = () => {
    setOpenSecondModal(false)
    window.location.reload();
  };

  const InfoModal = () => {
    setOpenInfoModal(true);
  }

  const handleInfoModal = () => {
    setOpenInfoModal(false);
  }

  const viewAgreementHandler = (e, id) => {
    const detailedIDRecord = pastAgrRecord.filter(el => el.id === id);
    setDetailedRecord({ ...detailedIDRecord[0] });
    setDetailedRecordID(id);
    setViewAgreement(true);
  }

  const viewAgrGoBackHandler = () => {
    setViewAgreement(false);
  }

  const purchaseHistoryHandler = () => {
    setIsPurchaseHistory(true);
  }

  const purchaseHistoryGoBackHandler = () => {
    setIsPurchaseHistory(false);
  }




  function SubsDiscFunc(evt, disc) {
    setSubsDurationDisc(disc);
  }
  function SubsFunc(evt, duration,coupon="") {
    setCoupon(coupon)
    setSubsDuration(duration);
    setStripeShow(true);
  }
  function openCity(evt, cityName) {
    // handle pinverification pa
    const pinVar = userObj.is_otp_for_pa == (1 || true) ? true : false;

    setPinCodeController(pinVar);

    setTabVar(true);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tb-links");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
    if (cityName === "Agr") {
      setAgreements(true);
      setPastAgr(false);
      setSubscription(false);

    } else if (cityName === "pastAgr") {
      setAgreements(false);
      setPastAgr(true);
      setApiControllingPA(true);
      setSubscription(false);
    } else {
      setAgreements(false);
      setPastAgr(false);
      setSubscription(true);
    }
  }

  React.useEffect(() => {

    document.getElementById("Agr").style.display = "block";
    document.getElementById("pastAgr").style.display = "none";
    document.getElementById("subs").style.display = "none";


  }, []);

  React.useEffect(() => {
    dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
  }, [agreements, pastagr]);
  // handle past agreement list
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (pastAgrRecord === undefined) {
        let searchParams = "";
        if (searchFormData.partyName !== "" || searchFormData.dateFrom !== "" || searchFormData.dateTo != "") {
          let dateFrom = searchFormData.dateFrom === "" ? "" : moment(searchFormData.dateFrom).format("MM-DD-YYYY");
          let dateTo = searchFormData.dateTo === "" ? "" : moment(searchFormData.dateTo).format("MM-DD-YYYY");
          searchParams = `name=${searchFormData.partyName}&dateFrom=${dateFrom}&dateTo=${dateTo}`
        }
        dispatch({ type: "USE_STATE", response: [], flash_message_show: false });
        overlay(true);
        HttpRequest.makeRequest(
          ApiRoutes.getContractList.method,
          `${ApiRoutes.getContractList.end_point}?${searchParams}`
        )
          .then((response) => {
            if (response.resCode == 200) {
              overlay(false);
              setPastAgrRecord(response.data);
              // dispatch({type:"SET_STATE", response:response, flash_message_show: true});
            } else if (response.resCode == 400 && response.message == "Contract not found") {
              overlay(false);
              setPastAgrRecord(null);
              // dispatch({type:"SET_STATE", response:response, flash_message_show: true});
            } else {
              overlay(false);
              // dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
            }
          })
          .catch(err => {
            devLog('error', err);
          });

      }
    }
  }, [apiControllingPA, searchControllVar]);

  // check for subscription
  React.useEffect(() => {
    if (subscription) {
      overlay(true);
      HttpRequest.makeRequest(
        ApiRoutes.getSubsStatus.method,
        ApiRoutes.getSubsStatus.end_point
      )
        .then((response) => {
          if (response.resCode == 200) {
            overlay(false);
            setPackageData(response.data);
            console.log("response.resCode",packageData)
            setIsSubsValid(true);
          } else {
            overlay(false);
          }
        })
        .catch(err => {
          devLog('error', err);
        });
    }
  }, [subscription, subscriptionSuccess]);

  // handle search click
  const handleSearchClick = () => {
    setSearchControllVar(!searchControllVar);
    setPastAgrRecord(undefined)
  }

  // handle pa pin verification Controller from userinput
  const handlePAPinConfirmation = () => {
    setPinCodeController(false);
  };

  const handleViewPackage = () => {
    setIsPackageView(true);
  };

  const handleSubsNotification = () => {
    setSubscriptionSuccess(!subscriptionSuccess);
  };

  // flash message styling
  const flashMessageStyling = {
    width: '100%'
  }
  return (
    <>
      {agreements ?
        (<Helmet>
          <title>Consent - New Consent</title>
        </Helmet>) :
        pastagr ?
          (<Helmet>
            <title>Consent - Past Consent</title>
          </Helmet>) :
          subscription ?
            (<Helmet>
              <title>Consent - Subscription</title>
            </Helmet>) : ""}
      <Navbar />

      <div className="hmprofile-main">
        <div className="container hmprofile-main-inner">
          {(state.screenWidth <= 768) && (<Link to="/home">
            <img className="login-header-img login-header-modified home-page-logo" src="./assets/images/newlogo.png" alt=" login header " width="72" height="57" />
          </Link>)}
          <div className="row">
            <div className="col-md-4 hmprofile-veryinner">
              <div
                className={`${agreements && "hm-consent-outline  active"} hmprofile-box-transparent hm-consent tb-links`}
                // className={ agreements ? "hmprofile-box-transparent active tb-links " : "hmprofile-box-transparent tb-links "} 
                onClick={(event) => openCity(event, 'Agr')}
              >
                <img
                  // src={agreements ? "./assets/images/profile-first-color.png" : "./assets/images/profile-first-icon.png"}
                  src="./assets/images/profile-first-color.png"
                  alt="profile icon" />
                <h3 >New Consent</h3>
              </div>
            </div>
            <div className="col-md-4 hmprofile-veryinner">
              <div
                className={`${pastagr && "hm-pastAgr-outline active"} hmprofile-box-transparent  hm-pastAgr tb-links`}
                // className={pastagr ? "hmprofile-box-transparent active tb-links  hm-pastAgr hm-pastAgr-outline" : "hmprofile-box-transparent tb-links hm-pastAgr"} 
                onClick={(event) => openCity(event, 'pastAgr')}>
                <img
                  // src={pastagr ? "./assets/images/profile-second-color.png" : "./assets/images/profile-second-icon.png" } 
                  src="./assets/images/profile-second-color.png"
                  alt=" profile icon" />
                <h3>Past Consents</h3>
              </div>
            </div>
            <div className="col-md-4 hmprofile-veryinner">
              <div
                className={`${subscription && "active hm-subs-outline"} tb-links  hm-subs hmprofile-box-transparent `}
                // className={subscription ? "hmprofile-box-transparent active tb-links  hm-subs hm-subs-outline" : "hmprofile-box-transparent tb-links hm-subs"} 
                onClick={(event) => openCity(event, 'subs')}>
                <img
                  // src= { subscription ? "./assets/images/profile-third-color.png" : "./assets/images/profile-third-icon.png" } 
                  src="./assets/images/profile-third-color.png"
                  alt="profile icon" />
                <h3>Subscription</h3>
              </div>
            </div>
          </div>
          <div id="Agr" className="tabcontent">
            <div className='row'>
              <div className="col-md-12 hp-newagr-topheading">
                <h1>New Consent</h1>
              </div>
              <div className='col-md-12 '>
                <div className='hp-newagr-formcontent'>
                  <MainController handleModal={handleModal} />
                </div>
              </div>
            </div>
          </div>

          {/* PAST AGREEMENTS */}
          <div id="pastAgr" className="tabcontent">
            <div className='row'>
              <div className="col-md-12 hp-newagr-topheading d-flex justify-content-between">
                <h1 >Past Consents</h1>
              </div>
              <div className='col-md-12 '>
                <div className='hp-newagr-formcontent'>
                  {!viewAgreement ?
                    (
                      pinCodeController ? (
                        <>
                          <PAPinVerification handlePAPinConfirmation={handlePAPinConfirmation} userObj={userObj} flashCSS={flashMessageStyling} />
                        </>
                      ) : (
                        <>
                          <div>
                            <PAFilter handleSearchClickParent={handleSearchClick} searchParamChangeParent={searchParamChange} searchFormDataParent={searchFormData} />
                            <ListingPA record={pastAgrRecord} handleViewAgreement={viewAgreementHandler} />
                          </div>
                        </>
                      )


                    ) :
                    (<FormWizardPA goBack={viewAgrGoBackHandler} detailedRecord={detailedRecord} />)}
                </div>
              </div>
            </div>
          </div>

          <div id="subs" className="tabcontent">
            <div className='row'>
              <div className="col-md-12 hp-newagr-topheading">
                <h1>Subscription</h1>
              </div>
              <div className='col-md-12 '>
                <div className='hp-newagr-formcontent'>
                  {
                    (!isSubsValid) && (
                      <div className='text-center mt-3 mb-5'>
                        <h5 style={{ 'color': 'red' }}>You Are Not Currently
                          Subscribed</h5>
                      </div>
                    )
                  }
                  <SubscriptionPackages
                    isPurchaseHistory={isPurchaseHistory}
                    SubsFunc={SubsFunc}
                    subsDuration={subsDuration}
                    purchaseHistoryHandler={purchaseHistoryHandler}
                    purchaseHistoryGoBackHandler={purchaseHistoryGoBackHandler}
                    packageData={packageData}
                    isSubsValid={isSubsValid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSecondModal}
        onClose={handleSecondModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSecondModal}>
          <Box sx={style} className="modal-responsive">
            <Typography id="transition-modal-description" sx={{ mt: 2 }} align="center" className="hp-newagr-modal-maincontent">
              Your Consent has been saved successfully. You can view the history of your Consents in the Past Consents section.
            </Typography>
            <img onClick={handleSecondModalClose} src="./assets/images/navbar-logo-iq.png" alt="" className="modal-img" />
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openFirstModal}
        onClose={handleFirstModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFirstModal}>
          <Box sx={style} className="modal-responsive">
            <img src="./assets/images/navbar-logo-iq.png" alt="" className="modal-img" />
            <Typography id="transition-modal-description" sx={{ mt: 2 }} align="center" className="hp-newagr-modal-maincontent">
              Consent can be withdrawn at any time.
            </Typography>
            <Button onClick={handleFirstModalClose} className="hp-newagr-modal-btn secondmodal-special">I Understand</Button>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openInfoModal}
        onClose={handleInfoModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInfoModal}>
          <Box sx={style} className="modal-responsive">
            <Typography id="transition-modal-description" sx={{ mt: 2 }} align="center" className="hp-newagr-modal-maincontent mb-0 mt-4">
              Past Consents available at:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 3 }} align="center" className="hp-newagr-modal-maincontent mt-2 mb-3">
              www.TheConsent.com !
            </Typography>
            <img src="./assets/images/navbar-logo-iq.png" onClick={handleInfoModal} alt="" className="modal-img img-clickable" />
          </Box>
        </Fade>
      </Modal>

      <Stripe
        currentSelectedPkg={subsDuration}
        handleModalClose={handleModalClose}
        stripeShow={stripeShow}
        handleSubsNotification={handleSubsNotification}
        coupon={coupon}
      />

    </>
  );
}