import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../Navbar/Navbar';
import { Link, useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import { devLog, getStorageData, overlay, setStorageData } from '../../utils/Helper';
import FlashMessage from '../FlashMessage/index';
import Constants from '../../utils/Constants';
import HttpRequest from '../../Services/HttpRequest';
import { Context } from '../../Store/store';
import ApiRoutes from '../../Services/ApiRoutes';



export default function Profile() {

    const user = getStorageData(Constants.session_object);
    const history = useHistory();
    const [userNameEdit, setUserNameEdit] = React.useState(true);
    const [username, setUsername] = React.useState(user.username || "");
    const [state, dispatch] = React.useContext(Context);

    const useFocus = () => {
        const htmlElRef = React.useRef(null)
        const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

        return [htmlElRef, setFocus]
    }
    const [inputRef, setInputFocus] = useFocus();
    const handleUserNameEdit = () => {
        setUserNameEdit(false)
    };


    // handler for input change 
    const handleOnChange = (e) => {
        setUsername(e.target.value);
    }

    // update user handler
    const handleUserNameSave = (e) => {
        e.preventDefault();
        setUserNameEdit(true);
        overlay(true);
        dispatch({
            type: "SET_STATE",
            response: [],
            flash_message_show: false
        });
        const params = new FormData();
        params.append('username', username);
        HttpRequest.makeRequest(
            ApiRoutes.updateUserProfile.method,
            ApiRoutes.updateUserProfile.end_point,
            params
        ).then((response) => {
            if (response.resCode === 200) {
                overlay(false);
                dispatch({
                    type: "SET_STATE",
                    response: response,
                    flash_message_show: true
                });
                setStorageData(Constants.session_object, response.data);
                setTimeout(() => {
                    history.push('/home');
                }, 2000);
            } else {
                overlay(false);
                dispatch({
                    type: "SET_STATE",
                    response: response,
                    flash_message_show: true
                });
            }
        }).catch(err => {
            devLog('error', err)
        });
    }

    // display no flash message
    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
        if (!userNameEdit) {
            setInputFocus();
        }
    }, [userNameEdit]);

    // flash message styling
    const flashMessageStyling = {
        width: '100%'
    }

    return (
        <>
            {userNameEdit ?
                (<Helmet>
                    <title>Consent - Profile</title>
                </Helmet>) :
                (
                    <Helmet>
                        <title>Consent - Edit Profile</title>
                    </Helmet>
                )}
            <Navbar />
            <div className="hmprofile-main">
                <div className="container hmprofile-main-inner">
                    <div className='row'>
                        <div className="col-md-12 hp-newagr-topheading">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h1 className='mt-0'>Profile</h1>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-12 '>
                            <div className='hp-newagr-formcontent'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <FlashMessage cssStyles={flashMessageStyling} />
                                    </div>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="cousenteeName">Full Name</label>
                                        <div className='profile-name-ip'>
                                            <input
                                                type="text"
                                                id="cousenteeName"
                                                placeholder={user && user.username}
                                                disabled={userNameEdit}
                                                value={username}
                                                onChange={handleOnChange}
                                                ref={inputRef}
                                            />
                                            {userNameEdit ? (<button className='profile-name-edit' onClick={handleUserNameEdit}>Edit</button>) :
                                                (<button className='profile-name-edit' onClick={handleUserNameSave}>Save</button>)}
                                        </div>
                                    </div>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="cousenterName">Email Address</label>
                                        <input type="email" id="cousenterName" placeholder={user && user.email} disabled />
                                    </div>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="changePassword">Password</label>
                                        <div className='profile-name-ip'>
                                            <input type="password" id="changePassword" value='Travis' disabled />
                                            <Link to="/change-pass" className='profile-name-edit'>Change</Link>
                                        </div>
                                    </div>
                                    <div className='col-md-12 hp-newagr-form phonenum-special mb-3'>
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <div className='profile-name-ip'>
                                            <input type="text" placeholder="+1 (123) 456-7890" id="phoneNumber" disabled />
                                            <Link to="change-num" className='profile-name-edit'>Edit</Link>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className='col-md-12 hp-newagr-form'>
                                        <label htmlFor="pinCode">Pin Code for Past Consents</label>
                                        <div className='profile-name-ip'>
                                            <input type="password" id="pinCode" value='hell' disabled />
                                            <Link to="/change-pin" className='profile-name-edit'>Change</Link >
                                        </div>
                                    </div>

                                    <div className='col-md-12 hp-newagr-form'>
                                        <div className='d-flex justify-content-between'>
                                            <p className='profile-sigpara mb-0'>Signature</p>
                                            <Link
                                                to="/edit-sig"
                                                className='profile-name-edit editsig-special'>Edit Signature</Link >
                                        </div>
                                        <div className='profile-sigimg text-center'>
                                            {user && user.signature_image !== null && <img src={user.signature_image} alt="user signature " />}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};