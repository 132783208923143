import React from 'react';
import { Link } from 'react-router-dom';
import FlashMessage from '../FlashMessage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FacebookLogin from "react-facebook-login";
// import GoogleLogin from "react-google-login";
import { useGoogleLogin } from '@react-oauth/google';
import { FaFacebookF } from "react-icons/fa";
import Constants from '../../utils/Constants';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
const axios = require('axios').default;


export default function Login({ submitForm, handleFacebookLogin, handleGoogleLogin, deviceToken }) {

    const [eye, setEye] = React.useState(true);
    const [type, setType] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [showPassword, setShowPassword] = React.useState("password");

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        submitForm(event, email, pass);
    }

    const passwordShowHandler = () => {
        showPassword == "password" ? setShowPassword("text") : setShowPassword("password");
        setEye(!eye);
        setType(!type);
    }

    const responseFacebook = async (response) => {
        // console.log('fb resp:', response)
        await handleFacebookLogin(response);
    }

    // const responseGoogle = (response) => {
    //     console.log('resp:',response)
    //     handleGoogleLogin(response);
    // };
    // const googleLogin = useGoogleLogin({
    // onSuccess: async handleGoogleLogin({ code }),
    // onSuccess: async ({ code }) => {
    //   const tokens = await axios.post( ApiRoutes.socialLogin.end_point, {  // http://localhost:3001/auth/google backend that will exchange the code
    //     code,
    //   });

    //   console.log(tokens);
    //     await handleGoogleLogin(code);
    //     },
    //     flow: 'auth-code',
    //   });

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            //   console.log(tokenResponse);
            // fetching userinfo can be done on the client or the server
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => {
                    handleGoogleLogin(res.data)
                });

            //   console.log(userInfo);

        },
        // flow: 'implicit', // implicit is the default
    });

    const failureResponseGoogle = (response) => {
        // console.log('error',response)
    };
    //////
    // {
    //     "iss": "https://accounts.google.com",
    //     "nbf": 1658483714,
    //     "aud": "304531247476-58f940f3b0dgrupg95cdo8b51fspupdv.apps.googleusercontent.com",
    //     "sub": "101420548435171622356",
    //     "hd": "retrocube.com",
    //     "email": "developer@retrocube.com",
    //     "email_verified": true,
    //     "azp": "304531247476-58f940f3b0dgrupg95cdo8b51fspupdv.apps.googleusercontent.com",
    //     "name": "John Mohr",
    //     "picture": "https://lh3.googleusercontent.com/a/AItbvmlRSqAxb-E3i2xh_Wn35sbwMTL9OgTo-5IoVek0=s96-c",
    //     "given_name": "John",
    //     "family_name": "Mohr",
    //     "iat": 1658484014,
    //     "exp": 1658487614,
    //     "jti": "68df61d6b6e909239db404affc95ee4f70132eaf"
    //   }
    //////

    return (
        <>
            <div className="rg-maincontainer">

                <main className="rg-form rg-form-login">
                    {/* <form > */}

                    <Link to="/home">
                        <img className="login-header-img login-header-modified" src="./assets/images/newlogo.png" alt=" login header " width="72" height="57" />
                    </Link>
                    <h1 className="rg-form-heading">Hello</h1>
                    <p className="rg-form-para">Login or Sign Up to Continue</p>
                    <FlashMessage />
                    <div className="rg-form-floating">
                        <label htmlFor="floatingInput">Email Address</label>
                        <input name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} className="rg-formcontrol" id="floatingInput" />
                    </div>
                    <div className="rg-form-floating login-pass">
                        <label htmlFor="floatingPassword">Password</label>
                        <input name="password"
                            type={showPassword}
                            className="rg-formcontrol rg-mbpass"
                            value={pass}
                            onChange={e => setPass(e.target.value)}
                            id="floatingPassword" />
                        {/* <i className="fa fa-lock"></i> */}
                        {
                            eye ? <VisibilityOffIcon
                                onClick={passwordShowHandler}
                                className="fa-eye-slash"
                            /> :
                                <VisibilityIcon
                                    onClick={passwordShowHandler}
                                    className="fa-eye-slash"
                                />
                        }
                        {/* <i onClick={passwordShowHandler} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>   */}
                        <Link className="login-forgotpass login-fp-afterpass lg-fp-special" to="/forgot-pass">Forgot Password?</Link>
                    </div>
                    <div className="row btn-area btn-area-res mt-3">
                        <div className='col-md-6 col-12 pr-md-1 '>
                            <button className="btn-loginSocial  login-social-fb" onClick={googleLogin}>
                                <img className="mr-2 " src="./assets/images/googleicon.png" alt=" google icon " width="25" height="25" />
                                Login with Google
                            </button>
                            {/* <GoogleLogin 
                            clientId={Constants.CLIENT_ID}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={failureResponseGoogle}
                            cookiePolicy={'single_host_origin'}
                            /> */}

                        </div>

                        <div className='col-md-6 col-12 pl-md-1'>
                            <FacebookLogin
                                appId={Constants.FB_APP_ID}
                                autoLoad={false}
                                isMobile={false}
                                disableMobileRedirect={true}
                                fields="name,email,picture.type(large)"
                                callback={responseFacebook}
                                cssClass="btn-loginSocial  login-social-fb"
                                icon={<img className="mr-2 " src="./assets/images/fbicon.png" alt=" facebook icon " width="25" height="25" />}
                            />
                        </div>
                    </div>
                    <button
                        className="rg-formbutton lg-btnmargin lg-btn-special"
                        style={{ 'marginTop': '25px', 'marginBottom': '5px' }}
                        // type="submit"
                        onClick={handleLoginSubmit}>Login</button>
                    <p className="rg-form-para ft">Don't have an Account? <Link to="/register" style={{ 'color': '#666ED4' }}> SIGN UP</Link> </p>
                    {/* </form>  */}

                    {/* <button className="btn-signup btn-facebook font-17 text-white">
                                Sign in with Facebook
                        </button> */}

                </main>

            </div>
        </>
    );
};
