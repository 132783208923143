/* eslint-disable no-array-constructor */
// "use strict";
var CryptoJS = require("crypto-js");
const _ = require('lodash');
const moment = require('moment');
const Constants = require("./Constants");
const secretKey = 'app.resarchHound753';

    const devLog = function(message, params = "") {
        if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "dev") {

        }
        return true;
    };

    const getStorageData = function(key) {
 
        let data = _.isEmpty(localStorage.getItem(key)) ? {} : (localStorage.getItem(key));
        if (data.length) {
            var bytes = CryptoJS.AES.decrypt(data, secretKey);
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return _.isEmpty(decryptedData) ? {} : decryptedData;
        }
        
    };

    const setStorageData = function(key, value) {
        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
        localStorage.setItem(key, ciphertext);

    };
    
    const setLanguageStorageData = function(key, value){
        var cipherLanguageText = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
        localStorage.setItem(key,cipherLanguageText);
    };
    
    const removeStorageData = function() {
        localStorage.clear();
        window.location.href = Constants.base_path + "/login";
    };

    const loadScript = function(src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(tag);
    };
 
    const overlay = function(is_show = false) {
        if (is_show == true) {
            if (document.getElementById("overlay"))
                document.getElementById("overlay").style.display = "block";
        } else {
            if (document.getElementById("overlay"))
                document.getElementById("overlay").style.display = "none";
        }
    };

    const dateFormat = function(given_date) {
        return moment(given_date).format("YYYY-MM-DD hh:mm:ss");
    };

    const dateFormattoYMD = function(given_date) {
        return moment(given_date).format("YYYY-MM-DD")
    };

    const formatPhoneNumber = function(number) {
        let formatedNumber = number.split(' ');
        let joinedLastPart = formatedNumber.slice(1,).join('');
        let finalNumberToFormat = [formatedNumber[0],joinedLastPart].join('-');
        return finalNumberToFormat;
    };

    const randomid = function(length) {
        let result           = [];
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * 
     charactersLength)));
       }
       return result.join('');
    };

    const removeOrAddElement = function(listOptions, element){
        if(listOptions.includes(element)){
            let elementIndex = listOptions.indexOf(element);
            listOptions.splice(elementIndex, 1);
        }else {
            listOptions.push(element);
        }
        return listOptions
    };
    
    const handleMCCheckBox = function(data,itemID){
        let checkedItem = data.findIndex(el => el.id == itemID);
        let newArray = data.filter( el => el.id != itemID );    
        let modifiedItem = {...data[checkedItem], value: !data[checkedItem].value};
        newArray.splice(checkedItem,0,modifiedItem)
        return newArray
    };

    
    const regexPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    const emailVerification = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const passwordInstruction = new Array("Password should contains:", "At least 1 lowercase alphabetical character", "1 Capital alphabetical character", "1 special character: ~!@#$%^&*()-_+={}[]|:<>,./?","1 numeric character","Must be eight characters or longer");

export {devLog, getStorageData, setStorageData, setLanguageStorageData, removeStorageData, loadScript, overlay, dateFormat, dateFormattoYMD, formatPhoneNumber, randomid, removeOrAddElement, handleMCCheckBox, regexPassword, emailVerification, passwordInstruction  };