import React from 'react';
import { Helmet } from 'react-helmet-async';
import { overlay, devLog, getStorageData, setStorageData  } from '../../utils/Helper';
import Constants from '../../utils/Constants';
import { Context } from '../../Store/store';
import { useHistory } from 'react-router-dom';
import ChangePinCode from './ChangePinCode';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';

const ChangePinCodeLogic = () => {

    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    const userObj = getStorageData(Constants.session_object);

    // update profile for isPAOTPSET
    const isPAOTPSetUpdate = () => {
        const params = new FormData();
      let otpPA = !(userObj && userObj.is_past_agreement_otp_set);
      otpPA = otpPA ? "1" : "0";
      params.append('is_past_agreement_otp_set', otpPA);
      HttpRequest.makeRequest(
        ApiRoutes.updateUserProfile.method,
        ApiRoutes.updateUserProfile.end_point,
        params
      )
      .then( (response) => {
          if(response.resCode === 200){
            //   overlay(false);
            //   dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
              setStorageData(Constants.session_object, response.data);              
          }else{
              overlay(false);
              dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
          }
      })
      .catch(err => {
          devLog("error", err);
      });
    }
    // api call general func
    const apiCallFunc = (argOne,argTwo,event,argThree=null) => {
        let argThreeNew = argThree === null ? 'abcd' : argThree;
        if (argOne === "" || argTwo === "" || argThreeNew === "" ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, flash_message_show: true});
        }else if (argOne.length != 4 || argTwo.length != 4 || argThreeNew.length != 4 ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'Length of Pin Code should be 4 characters!'}, flash_message_show: true});
        }
         else if ( argOne !== argTwo ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'New pin and confirm new pin must be same'}, flash_message_show: true});
        } else if ( argOne === argThreeNew ){
            dispatch({type: 'SET_STATE', response:{status: false, resCode: 400, data: null, message: 'New pin and old pin must be different'}, flash_message_show: true});
        }
        else{
            const urlEndPoint = userObj.is_past_agreement_otp_set == 0 ? ApiRoutes.setPastAgreeOTP.end_point : ApiRoutes.updatePastAgreeOTP.end_point;
            const form = event.target;
            const params = new FormData(form);
            params.delete('otpConfirm');
            overlay(true);
            dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
            HttpRequest.makeRequest(
                ApiRoutes.updatePastAgreeOTP.method,
                urlEndPoint,
                params
            ).then((response) => {
                if ( response.resCode === 200){
                    if(userObj.is_past_agreement_otp_set == 0) {isPAOTPSetUpdate()};
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true});                
                    setTimeout(() => {
                        history.push('/profile');
                    }, 2000);
                }else {
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                }
            }).catch(err => {
                devLog("error", err);
            });

        }
    };

    // forgot pin handler 
    const forgotPinCodeHandler = () => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
        overlay(false);
        HttpRequest.makeRequest(
            ApiRoutes.forgotPastAgreeOTP.method,
            ApiRoutes.forgotPastAgreeOTP.end_point
        )
        .then( (response) => {
            if(response.resCode === 200){
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
                setTimeout(() => {
                    history.push('/profile');
                }, 2000);
                // setStorageData(Constants.session_object, response.data);              
            }else{
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
            }
        })
        .catch(err => {
            devLog("error", err);
        });
    };

    const sumbitForm = (event) => {
        if(userObj.is_past_agreement_otp_set == 0){
            apiCallFunc(event.target.otp.value, event.target.otpConfirm.value,event);
        }else {
            apiCallFunc(event.target.new_otp.value, event.target.confirm_otp.value,event, event.target.old_otp.value);
        }
        
    };

    // flash message styling
    const flashMessageStyling = {
        width: '100%'
    }

    return(
        <>
            <Helmet>
                <title>Consent - Change Pin Code</title>
            </Helmet>
            <ChangePinCode 
            submitForm = {sumbitForm} 
            flashCSS={flashMessageStyling} 
            userObj={userObj} 
            forgotPinCodeHandler={forgotPinCodeHandler}/>
        </>
    );
};

export default ChangePinCodeLogic;