import React from 'react';
import { useHistory } from 'react-router-dom';
import NAVerificationCode from './NAVerificationCode';
import { Context } from '../../Store/store';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import { devLog, overlay } from '../../utils/Helper';

const NAVCLogic = (props) => {
    const [state, dispatch] = React.useContext(Context);
    console.log("props",props);
    const history = useHistory();
    const forget_token = props && props.history && props.history.location && props.history.location.state.forget_token;
    const email = props && props.history && props.history.location && props.history.location.state.email;
    const type = props && props.history && props.history.location && props.history.location.state.type;
    const submitForm = (params) => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show:false});
        overlay(true);
        params.append('forget_token',forget_token)
        HttpRequest.makeRequest(
            ApiRoutes.loginVerifyCode.method,
            ApiRoutes.loginVerifyCode.end_point,
            params
        ).then((response) => {
            if(response.resCode === 200){
                overlay(false);
                dispatch({type:"SET_STATE", response:response, flash_message_show:true});
                setTimeout(() => {history.push({pathname:"/reset-pass",  state: { forgot_token: forget_token }})},1000);
            }else {
                dispatch({type:'SET_STATE', response:response, flash_message_show:true});
                overlay(false);
            }
        }).catch(err=>{
            devLog("error",err);
        });
    };

    const handleResend = () => {
        dispatch({type: "SET_STATE", response: [], flash_message_show: false});
        overlay(true);

        const params = new FormData();
        params.append("type","forget_password");
        params.append("forget_token",forget_token);
        params.append("email",email);
        params.append("code_resend",1)
        HttpRequest.makeRequest(
            ApiRoutes.loginGenerateFPCode.method,
            ApiRoutes.loginGenerateFPCode.end_point,
            params)
            .then((response) => {
                if(response.resCode === 200){
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                    setTimeout(()=>{history.push({
                        pathname:'/na-verification-code',
                        state: response.data
                    })},2000);
                }else{
                    dispatch({type:'SET_STATE', response: response, flash_message_show: true})
                    overlay(false);
                }
            }).catch(err=>{
            devLog("error",err);
        });
    };


    React.useEffect(()=>{
        dispatch({type:'SET_STATE',response:[], flash_message_show:false});
    },[]);


    return(
        <>
            <NAVerificationCode handleResend={handleResend} submitForm={submitForm}/>
        </>
    );
}

export default NAVCLogic;