import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../components/pages/NotFound/NotFound";
import Register from "../components/Register/RegisterLogic";
import Login from "../components/Login/LoginLogic";
import ForgotPassword from "../components/ForgotPassword/ForgotPasswordLogic";
import VCLogic from "../components/VerificationCode/VCLogic";
import CPNLogic from "../components/UpdatePhoneNumberLogin/ChangePhoneNumberLogic";
import LandingPage from "../components/LandingPage/LandingPage";
import NAVerificationCode from "../components/NoAuthVerificationCode/NAVCLogic";
import ResetPasswordLogic from "../components/ResetPassword/ResetPasswordLogic";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path={"/"} component={LandingPage} />
      {/* <Route exact path={"/navbar"} component={BasicTabs}/> */}
      
      
      <Route exact path={"/forgot-pass"} component={ForgotPassword} />
      <Route exact path={"/register"} component={Register} />
      <Route axact path={"/login"} component={Login} />
      <Route exact path={"/verification-code"} component={VCLogic} />
      <Route exact path={"/change-phone-number-login"} component={CPNLogic} />
      <Route exact path="/na-verification-code" component={NAVerificationCode}  />
      <Route exact path="/reset-pass" component={ResetPasswordLogic}  />

      <Route component={NotFound} />
    </Switch>
  );
};

export default PublicRoutes;
