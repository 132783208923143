import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    CardElement, Elements, useStripe, useElements,
} from '@stripe/react-stripe-js';
import {Context} from '../../Store/store';
import {devLog, setStorageData} from '../../utils/Helper';
import Constants from '../../utils/Constants';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import FlashMessage from '../FlashMessage';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import {useDebounce} from "../Home/UseDebounce";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,

    bgcolor: 'background.paper', // border: '2px solid #000',
    p: 4,
    textAlign: 'center',
    padding: '50px 40px 50px 40px',
    borderRadius: '10px',
    boxShadow: '0 2px 6px 0 rgb(0 0 0 / 10%)',
    backgroundColor: '#fff',
    border: '1px solid transparent',
    outline: 'none'

}

const ColorButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText('#666ED4'), backgroundColor: '#666ED4', '&:hover': {
        backgroundColor: '#666ED4',
    },
}));

const CheckoutForm = ({currentSelectedPkg, stripeShow, handleModalClose, handleSubsNotification}) => {
    const [debounceCoupon,coupon, setCoupon] = useDebounce("");
    const stripe = useStripe();
    const elements = useElements();
    const [state, dispatch] = React.useContext(Context);
    const [isDisabale, setisDisable] = React.useState(false);
    const package_id = currentSelectedPkg == "month" ? 1 : 2;

    const closeModal = () => {
        setCoupon("");
        handleModalClose();
        dispatch({
            type: "SET_STATE", response: [], flash_message_show: false
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }
        if (!stripe) {
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        const result = await stripe.createToken(cardElement);
        if (result.error) {
            console.log(result.error.message);
        } else {
            let params = new FormData();
            params.append("package_id", package_id);
            params.append("stripeToken", result.token.id);
            params.append("promo_code", coupon);
            setisDisable(true);
            HttpRequest.makeRequest(ApiRoutes.user_subscription.method, ApiRoutes.user_subscription.end_point, params)
                .then((response) => {
                    let data = response.data;
                    if (response.resCode !== 200) {
                        dispatch({
                            type: "SET_STATE", response: response, flash_message_show: true
                        });
                        devLog("error ", response);
                        setisDisable(false);
                        handleSubsNotification();
                    } else {
                        setStorageData(Constants.session_object, response.data);
                        dispatch({
                            type: "SET_STATE", response: response, flash_message_show: true
                        });
                        setisDisable(false);
                        setTimeout(() => {
                            handleModalClose();
                            dispatch({
                                type: "SET_STATE", response: [], flash_message_show: false
                            });
                            handleSubsNotification()
                        }, 1500);
                    }
                })
                .catch((error) => {
                    devLog("error", error);
                });
        }
    };

    return (<Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={stripeShow}
            onClose={closeModal}
        >
            <Fade in={stripeShow}>
                <Box sx={style} className="modal-responsive">
                    <Typography id="spring-modal-title" variant="h5" component="h2" className="mb-3">
                        Stripe Payment
                    </Typography>
                    <FlashMessage/>
                    <form onSubmit={handleSubmit}>
                        <CardElement
                            options={{
                                hidePostalCode: true, style: {
                                    base: {
                                        fontSize: "16px", color: "#424770", "::placeholder": {
                                            color: "#aab7c4",
                                        },
                                    }, invalid: {
                                        color: "#9e2146",
                                    },
                                },
                            }}
                            className="form-control"
                        />
                        <div className="StripeCoupon" style={{"marginTop" :"10px"}}>
                           <input type="text" style={{"width" : "100%"}} maxLength="10"
                                       value={coupon}
                                  className="form-control"
                                       onChange={(e) => setCoupon(e.target.value)}
                                       placeholder="Add Coupon"/>
                        </div>
                        <ColorButton
                            type="submit"
                            disabled={!stripe || !elements || isDisabale}
                            className="mt-3"
                            size="large"
                            fullWidth
                        >
                            {(isDisabale) ? <span className="spinner-border spinner-border-sm mr-1"
                                                  role="status"
                                                  style={{'verticalAlign': 'middle'}}
                                                  aria-hidden="true"></span> : ''} Pay Now</ColorButton>
                    </form>
                </Box>
            </Fade>
        </Modal>

    );
};

const stripePromise = loadStripe(Constants.MIX_STRIPE_SANDBOX_KEY);

const Stripe = ({currentSelectedPkg, stripeShow, handleModalClose, handleSubsNotification, coupon}) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm
            currentSelectedPkg={currentSelectedPkg}
            stripeShow={stripeShow}
            handleModalClose={handleModalClose}
            coupon={coupon}
            handleSubsNotification={handleSubsNotification}/>
    </Elements>);

export default Stripe;