import React from 'react';
import { overlay, devLog } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Context } from '../../Store/store';
import Button from '@mui/material/Button';
// import { useHistory } from 'react-router-dom';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const FormWizardPA = ({goBack, detailedRecord}) => {
  
    const [state, dispatch] = React.useContext(Context);
    const [record, setRecord] = React.useState({});

    // agreement detailed view api call
  React.useEffect(() => {
    
    //     if (isInitialMount.current) {
    //     isInitialMount.current = false;
    // } else {
        // if (pastAgrRecord == undefined){
        dispatch({ type: "USE_STATE", response: [], flash_message_show: false});
        overlay(true);
        HttpRequest.makeRequest(
            ApiRoutes.getContractDetail.method,
            `${ApiRoutes.getContractDetail.end_point}/?id=${detailedRecord.id}`
            )
            .then((response) => {
            if(response.resCode == 200){
                overlay(false);
                setRecord(response.data);
                // dispatch({type:"SET_STATE", response:response, flash_message_show: true});
            }else {
            overlay(false);
            // dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
            }
        })
        .catch(err =>{
            devLog('error', err);
        });

        // }
    // }
    },[]);

    return(
       <>
            <KeyboardBackspaceIcon onClick={goBack} className="mb-4" style={{cursor: 'pointer'}}/>
           { Object.keys(record).length > 0 && (
            <>
            <div className="mb-3">
                {/* <KeyboardBackspaceIcon onClick={goBack}  style={{cursor: 'pointer'}}/>                 */}
                <h5 className='hp-postagree-heading '>
                    Contract Information
                </h5>
            </div>
            <p className='hp-postagree-p'>
            I, <span>{record.party_2_name != null ? record.party_1_name : ""}</span>, hereby declare my intentions to voluntarily engage in romantic relations with <span>{record.party_2_name != null ? record.party_1_name : ""}</span>, on <span>{detailedRecord.agreement_date != null ? detailedRecord.agreement_date : ""}</span> at <span>{detailedRecord.agreement_time != null ? detailedRecord.agreement_time : ""}</span>.
            </p>
            <p className='hp-postagree-p'>We hereby certify:</p>
            <ol className='hp-postagree-p'>
                <li key={1}>We are each at least 18 years old</li>
                <li key={2}>Neither of us are intoxicated</li>
                <li key={3}>Neither of us is under duress</li>
                <li key={4}>We have and will not exchange goods, monies, services, or any payment</li>
                <li key={5}>We will not distribute nor communicate in any fashion the existence of this agreement to anyone but ourselves, law enforcement, or lawyer*</li>
                <li key={6}>To the best of each of our knowledge neither of use has any sexually transmitted diseases</li> 
                <li key={7}>We understand that this agreement can be revoked at any time</li>
            </ol>

            <h5 className="hp-postagr-h5">CAN BE REVOKED AT ANY TIME</h5>
            
            <p className='hp-postagree-p'>
            We agree to the following acts:
            </p>
            <ul className={record.agreed_options && record.agreed_options.agreed.length > 0 ? 'hp-postagree-list' : ' hp-postagree-list pl-0'}>
            { record.agreed_options && record.agreed_options.agreed.length > 0 ? (record.agreed_options.agreed.map((el,index) => {
                
                return (
                                          
                        <li key={index}>
                            {el}
                        </li>                      
                    
                );
            })  
            ):
            <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p>
            }
            </ul>
        
            <p className='hp-postagree-p'>
            We agree to the following restrictions:
            </p>
            <ul className={record.agreed_options && record.agreed_options.restrictions.length > 0 ? 'hp-postagree-list' : ' hp-postagree-list pl-0'}>
            { record.agreed_options && record.agreed_options.restrictions.length > 0 ? (record.agreed_options.restrictions.map((el,index) => {
                return (
                                                                        
                        <li key={index}>
                            {el}
                        </li>                                             
                    
                );
            })  
            ):
            <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p>
            }
            </ul>
            <p className='hp-postagree-p mb-1' >Additional Provisions that we agree to:</p>
                <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list"> {record.additional_text != null ? record.additional_text : "N/A"}</p>
            {/* <div className="pa-addition-prov mb-3">
            </div> */}
            {/* <textarea rows="6" className='contactus-textarea mt-2 w-75'  /> */}
            {/* <p className='hp-newagr-specialpoints-beforeowner hp-postagree-list'>Lorem ipsum dolor sit amen cons</p> */}
            <div className="hp-newagr-pastagr-sig">
                <p className='hp-newagr-specialpoints-owner'>{record.party_1_name != null ? record.party_1_name : ""}</p>
                <div className="hp-newagr-pastagr-sigouter">
                    <img src={record.party_1_signature} alt="Consentee Signature"/>
                </div>
            </div>
            <div className="hp-newagr-pastagr-sig mt-4">
                <p className='hp-newagr-specialpoints-owner'>{record.party_2_name != null ? record.party_2_name : ""}</p>
                <div className="hp-newagr-pastagr-sigouter">
                    <img src={record.party_2_signature} alt="Consenter Signature"/>
                </div>
            </div>
            {/* <p className='hp-postagree-p font-theme-color mt-4'>*To the best of each individuals knowledge, we are clean of sexually transmitted diseases and other infections.</p> */}
            <p className='hp-postagree-p font-theme-color  mt-4'>*Failure to abide by this provision may be considered criminal in some jurisdictions and the aggrieved party will have grounds to sue for inter alia Breach of Contract, Intentional Infliction of Emotional Distress, and/or False Light.</p>
            
            <div className='text-center'>
                <Button 
                className="hp-newagr-btn" 
                onClick={goBack}
                color="secondary"            
                variant="contained"
                >Done</Button>
            </div> 
            </>
            )}
        
        </>
    );
};

export default React.memo(FormWizardPA);
