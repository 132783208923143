import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import {Link} from 'react-router-dom';

export default function LandingPage(){
    return(
        <>
            <Helmet>
                <title>The Consent</title>
            </Helmet>
             <div className="hp-main">
                <Link
                    to="/login"

                >
                    <img className="hp-img" src="./assets/images/homepage-icon.png" alt="home page image" />
                </Link>
             </div>
        </>
    );
    };