import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import ChangePhoneNumber from './ChangePhoneNumber';
import { Context } from '../../Store/store';
import { devLog, overlay, setStorageData } from '../../utils/Helper';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';
import Constants from '../../utils/Constants';

const ChangePhoneNumberLogic = () => {

    const history = useHistory();
    const [state, dispatch] = React.useContext(Context);
    const [varState, setVarState] = React.useState(false);
    const [paramsForm, setParamsForm] = React.useState({});

    // console.log(paramsForm);
    // React.useEffect(() => {
    //     dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
    //     if (varState){
    //             HttpRequest.makeRequest(
    //                 ApiRoutes.updateUserProfile.method,
    //                 ApiRoutes.updateUserProfile.end_point,
    //                 paramsForm
    //             )
    //             .then( (response) => {
    //                 if(response.resCode === 200){
    //                     overlay(false);
    //                     dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
    //                     setStorageData(Constants.session_object, response.data);                    
    //                     setTimeout(() => {
    //                         history.push('/profile');                        
    //                     }, 2000);
    //                 }else{
    //                     overlay(false);
    //                     dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
    //                 }
    //             }) 
    //             .catch(err => {
    //                 devLog("error", err);
    //             });
    //     }
    // }, [varState]);

    // verify phone number
    const submitForm = (params,event) => {
        // console.log('mobileno:',event.target.mobile_noAlready.value, event.target.phoneNumber.value)
        if( event.target.phoneNumber.value === ""){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
                });
        }
        else{
            setParamsForm(params);
            overlay(true);
            dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
            HttpRequest.makeRequest(
                ApiRoutes.sendmsgPN.method,
                ApiRoutes.sendmsgPN.end_point,
                params
            )
            .then( (response) => { 
                if(response.resCode === 200){
                    dispatch({
                        type:"SET_STATE", 
                        response: response, 
                        flash_message_show: true
                    });
                    overlay(false);
                    setTimeout(() => {
                        history.push("/verification-code") 
                    },  2000); 
                }else if(response.resCode == 410){
                    overlay(false);
                    dispatch({ type: "SET_STATE", response:{status: false, resCode: 400, data: null, message: 'Cannot change phone number if user has signed in via Facebook or Google.'}, flash_message_show: true});
              
                }else if(response.data !== null && response.data.errorCodeType === "409") {
                    // setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                    dispatch({
                        type:"SET_STATE", 
                        response: response, 
                        flash_message_show: true
                    });
                    overlay(false);
                }else{                 
                    overlay(false);
                    dispatch({ type: "SET_STATE", response:{status: false, resCode: 400, data: null, message: 'Mobile Number is associated with some other user'}, flash_message_show: true});
                }
            })
            .catch(err => {
                devLog("error", err);
            });
        }
    };
    return(
        <>
        <Helmet>
            <title>Consent - Change Phone Number</title>
        </Helmet>
        <ChangePhoneNumber submitForm = { submitForm } />
        </>
    );
};

export default ChangePhoneNumberLogic;