const _ = require("lodash");
const Helper = require('../utils/Helper');

const Reducer = (state, payload) => {
    let userObject = _.isEmpty(Helper.getStorageData('userObj')) ? {} : Helper.getStorageData('userObj');
    switch (payload.type) {
        case 'SET_STATE':
            return {
                ...state,
                data: typeof payload.response == "undefined" ? [] : payload.response,
                flash_message_show: typeof payload.flash_message_show == "undefined" ? false : payload.flash_message_show,
                userObj: typeof payload.userObj == "undefined" ? userObject : payload.userObj,
                lang: typeof payload.lang == "undefined" ? state.lang : payload.lang,
            }
        case 'UPDATE_SCREENWIDTH':
            return {
                ...state,
                screenWidth: payload.response.screenWidth,
            }
        default:
            return state;
    }
};
export default Reducer;