import React from 'react';
import { Helmet } from 'react-helmet-async';
import Register from './Register';
import { useHistory } from 'react-router-dom';
import HttpRequest from '../../Services/HttpRequest';
import { Context } from '../../Store/store';
import Constants from '../../utils/Constants';
import { devLog, overlay, setStorageData, regexPassword, passwordInstruction, emailVerification } from '../../utils/Helper';
import ApiRoutes from '../../Services/ApiRoutes';

const RegisterLogic = () => {

    const [state, dispatch] = React.useContext(Context); 
    const [record, setRecord] = React.useState([]);
    const history = useHistory();

    React.useEffect(() => {
        dispatch({ type: "USE_STATE", response: [], flash_message_show: false });
        overlay(true);
        HttpRequest.makeRequest(
            ApiRoutes.getAppContents.method,
            `${ApiRoutes.getAppContents.end_point}?identifier=terms`
        )
            .then((response) => {
                if (response.resCode === 200) {
                    overlay(false);
                    setRecord(response.data)
                } else {
                    overlay(false);
                    dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                }
            })
            .catch(err => {
                devLog('Error', err);
            });

    }, []);

    React.useEffect(() => {
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
    }, []);
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const submitForm = (event) => {
        const form = event.target;
        const pass = event.target.password.value;
        const name = event.target.username.value;
        const email = event.target.email.value;
        const cellno = event.target.phoneNumber.value;
        const confirmPass = event.target.confirmPassword.value;
        if (name !== "" && email !== "" && pass !== "" && confirmPass !== "" && cellno !== "") {
            if (pass !== "" && pass === confirmPass) {
                if (emailVerification.test(email)) {
                    if (regexPassword.test(pass)) {
                        let params = new FormData(form);
                        let mobile_no = event.target.phoneNumber.value.split(' ');
                        const mobile_code = mobile_no[0];
                        mobile_no.shift();
                        params.delete('confirmPassword');
                        params.delete('phoneNumber');
                        params.delete('phoneNumberCountry');
                        params.append('mobile_no', mobile_no.join(''));
                        params.append('mobile_code', mobile_code);
                        dispatch({ type: "SET_STATE", response: [], flash_message_show: false });
                        overlay(true);
                        HttpRequest.makeRequest(
                            ApiRoutes.userRegister.method,
                            ApiRoutes.userRegister.end_point,
                            params
                        ).then((response) => {
                            if (response.resCode === 200) {
                                scrollToTop();
                                setStorageData(Constants.session_key, `Bearer ${response.data.authentication_token}`);
                                overlay(false);
                                dispatch({ type: "SET_STATE", response: response, flash_message_show: true });
                                setTimeout(() => {
                                    history.push({
                                        pathname: "/verification-code",state: response.data
                                    })
                                }, 2000);
                            } else {
                                scrollToTop();
                                dispatch({
                                    type: "SET_STATE",
                                    response: response,
                                    flash_message_show: true
                                });
                                overlay(false);
                            }
                        }).catch(err => {
                            devLog("Error", err)
                        });
                    } else {
                        scrollToTop();
                        dispatch({
                            type: 'SET_STATE',
                            response: { status: false, resCode: 400, data: null, message: passwordInstruction },
                            flash_message_show: true
                        });
                    }
                } else {
                    scrollToTop();
                    dispatch({
                        type: 'SET_STATE',
                        response: { status: false, resCode: 400, data: null, message: 'Please insert a valid email!' },
                        flash_message_show: true
                    });
                }
            } else {
                scrollToTop();
                dispatch({
                    type: 'SET_STATE',
                    response: { status: false, resCode: 400, data: null, message: 'Password mismatch' },
                    flash_message_show: true
                });
            }
        } else {
            scrollToTop();
            dispatch({
                type: 'SET_STATE',
                response: { status: false, resCode: 400, data: null, message: 'Please insert all fields !' },
                flash_message_show: true
            });
        }
    };

    // React.useEffect(() => {
    //     dispatch({
    //         type: 'SET_STATE', 
    //         response:[], 
    //         flash_message_show: false
    //     });
    // }, []);

    return (
        <>
            <Helmet>
                <title>Consent - Register</title>
            </Helmet>
            <Register submitForm={submitForm} record={record} />
        </>
    );
};

export default RegisterLogic;