import React from 'react';
import PurchaseHistory from './PurchaseHistory';
import {devLog, overlay} from "../../utils/Helper";
import HttpRequest from "../../Services/HttpRequest";
import ApiRoutes from "../../Services/ApiRoutes";
import { Context } from '../../Store/store';
import FlashMessage from "../FlashMessage";
import {useHistory} from "react-router-dom";

const SubscriptionPackages = ({
                                  isPurchaseHistory,
                                  SubsFunc,
                                  subsDuration,
                                  purchaseHistoryHandler,
                                  purchaseHistoryGoBackHandler,
                                  packageData,
                                  isSubsValid
                              }) => {
    const [state, dispatch] = React.useContext(Context);
    const [isVisibility, setIsVisibility] = React.useState("visible");
    const history = useHistory();
    const PackageSelectHandler = (event, type) => {
        SubsFunc(event, type);
    };

    console.log("Subscription packageData",isSubsValid ,packageData,isVisibility)
    const purchaseHistoryHandlerInner = () => {
        purchaseHistoryHandler();
    };

    const cancelSubscriptionHandler = () => {
        if (window.confirm('Are you sure you want to cancel subscription?')) {
                dispatch({type: "USE_STATE", response: [], flash_message_show: false});
                overlay(true);
                HttpRequest.makeRequest(
                    ApiRoutes.subscriptionCancel.method,
                    ApiRoutes.subscriptionCancel.end_point
                )
                    .then((response) => {
                        if (response.resCode == 200) {
                            overlay(false);
                            setIsVisibility("hidden")
                            dispatch({type:"SET_STATE", response:response, flash_message_show: true});
                        } else {
                            overlay(false);
                            dispatch({type:"SET_STATE", response:response, flash_message_show: true});
                        }
                    })
                    .catch(err => {
                        devLog('error', err);
                    });
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    React.useEffect(()=>{

    },[isVisibility])

    return (
        <>
            {!isPurchaseHistory ? (<div className='row'>
                    <div className="col-md-12" style={{"text-align": "-webkit-center"}}>
                        <FlashMessage />
                    </div>
                    <div className="col-md-6">
                                {
                                    (packageData.length > 0 && packageData[0].package_id == 1 && isSubsValid)
                                        ?
                                        <>
                                            <div
                                                style={{"height": "250px","position" : "relative"}}
                                                className={(packageData.length > 0 && packageData[0].package_id == 1) ? ' hp-subs-leftbox hp-subs-bg' : ' hp-subs-leftbox'}>
                                                <p className='subs-pckg-name'>Monthly Package</p>
                                                <h4 style={{"color": "white"}}>
                                                    You are currently subscribed to this package.
                                                </h4>
                                                <p style={{"position": "absolute","bottom": "0px","right": "15px"}}
                                                    className='subs-pckg-name'>Expire date:
                                                    {packageData[0].expiry_date}</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                        <div  style={{"height": "250px","position" : "relative"}}
                                            onClick={(event) => PackageSelectHandler(event, 'month')}
                                            className={(packageData.length > 0 && packageData[0].package_id == 1) ? ' hp-subs-leftbox hp-subs-bg' : ' hp-subs-leftbox'}>
                                            <p className='subs-pckg-name'>Monthly Package</p>
                                            <h1>
                                                <span className='subs-pckg-dsign'>$</span>4
                                                <span className="subs-pckg-dtnewline">/month</span>
                                            </h1>
                                        </div>
                                        </>

                                }
                    </div>
                    <div className="col-md-6">
                        {
                            (packageData.length > 0 && packageData[0].package_id == 2 && isSubsValid)
                                ?
                                <>
                                    <div
                                        style={{"height": "250px","position" : "relative"}}
                                        className={(packageData.length > 0 && packageData[0].package_id == 2) ? 'hp-subs-leftbox hp-subs-bg' : ' hp-subs-leftbox'}>
                                        <p className='subs-pckg-name'>Yearly Package</p>
                                        <h4 style={{"color": "white"}}>
                                            You are currently subscribed to this package.
                                        </h4>
                                        <p style={{"position": "absolute","bottom": "0px","right": "15px"}}
                                           className='subs-pckg-name'>Expire date: {packageData[0].expiry_date}</p>
                                    </div>
                                </>
                                :
                                <>
                                    <div
                                        style={{"height": "250px","position" : "relative"}}
                                        onClick={(event) => PackageSelectHandler(event, 'year')}
                                        className={(packageData.length > 0 && packageData[0].package_id == 2) ? 'hp-subs-leftbox hp-subs-bg' : ' hp-subs-leftbox'}>
                                        <h1>
                                            <span className='subs-pckg-dsign'>$</span>40<span
                                            className='subs-pckg-durationInline'>/year</span>
                                        </h1>
                                        <p className="subs-pckg-durationtag">*Saves 17%</p>
                                    </div>
                                </>
                        }

                    </div>
                    <div className='col-md-12' style={{"display": "flex", "flex-direction": "row-reverse"}}>
                        <div className="hp-subs-discount text-center">
                            <div>
                                <div>
                                    <button className="hp-subs-discount-btn"
                                            onClick={purchaseHistoryHandlerInner}>Purchase
                                        History
                                    </button>
                                </div>
                            </div>
                        </div>
                        {(isSubsValid && (packageData.length > 0 && packageData[0].is_cancelled != 1)) ? <button style={{"font-weight": "600","visibility" : isVisibility}} className="btn btn-danger" onClick={cancelSubscriptionHandler}>Cancel
                            Subscription</button> : ""}
                    </div>
                </div>) :
                (
                    <PurchaseHistory goBack={purchaseHistoryGoBackHandler} isPurchaseHistory={isPurchaseHistory}/>
                )}
        </>
    );
};

export default SubscriptionPackages;