import React from 'react';
import Contactus from './Contactus';
import { Helmet } from 'react-helmet-async';
import { overlay, devLog } from '../../utils/Helper';
import { Context } from '../../Store/store';
import HttpRequest from '../../Services/HttpRequest';
import ApiRoutes from '../../Services/ApiRoutes';


const ContactUsLogic = () => {

    const [state, dispatch] = React.useContext(Context);
    const [contactusOptions, setContactusOptions] = React.useState([]);

    // get contact us options 
    React.useEffect(() => {
        overlay(true);
        dispatch({ type: "SET_STATE", response : [], flash_message_show: false});
        HttpRequest.makeRequest(
            ApiRoutes.contactusOptions.method,
            ApiRoutes.contactusOptions.end_point
        )
        .then( (response) => {
            if ( response.resCode === 200 ){
                overlay(false);
                setContactusOptions(response.data);
            } else {
                overlay(false);
            }
        })
        .catch(err => {
            devLog("error", err);
        });


    },[]);

    // submit form 
    const formSubmit = (params, event) => {
        if( event.target.type_id.value === "" || event.target.message.value === "" ){
            dispatch({
                type: 'SET_STATE', 
                response:{status: false, resCode: 400, data: null, message: 'Please insert All fields'}, 
                flash_message_show: true
                });
        }else{
            overlay(false);
        dispatch({ type: "SET_STATE", response: [], flash_message_show: false});
        HttpRequest.makeRequest(
            ApiRoutes.contactusSendMessage.method,
            ApiRoutes.contactusSendMessage.end_point,
            params
        )
        .then((response) => {
            if( response.resCode === 200){
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
            }else{
                overlay(false);
                dispatch({ type: "SET_STATE", response: response, flash_message_show: true});
            }
        })
        .catch(err => {
            devLog("error", err);
        });
        }        
    };

    return(
        <>      
        <Helmet>
            <title>Consent - Contact Us</title>
        </Helmet>
        <Contactus formSubmit = {formSubmit} contactusOptions={contactusOptions}/>
        </>
    );
};

export default ContactUsLogic;