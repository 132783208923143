import React, { useContext } from "react";
import { Context } from "../../Store/store";

function FlashMessage(props) {
  const stylesImported = props && props.cssStyles;
  const [state] = useContext(Context);
  const renderMessage = () => {
    if (state.flash_message_show === true) {
      switch (state.data.resCode) {
        case 400:
        case 600:
         
          var errorStyle =
            state.data.resCode === 400 || state.data.resCode === 600 ? { display: "block",width: '95%',borderRadius: '10px', ...stylesImported } : { display: "none" };
          var html = "";
          var errors = state.data.message;
          if ( typeof errors === Array || typeof errors === "object"){
            for (var key in errors) {
              if (errors.hasOwnProperty(key)) {
                html += "<p>" + errors[key] + "</p> " 
              }
            }
          }else {
            html += "<p style='margin-bottom:0px;'>" + errors + "</p>";
          }
          return (
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className="alert alert-danger"
              style={errorStyle}
            ></div>
          );
        case 200:
          var successStyle =
            state.data.resCode === 200 ? { display: "block",width: '95%',borderRadius: '10px', ...stylesImported} : { display: "none" };
          return (
            <div className="alert alert-success" style={successStyle}>
              <p className="mb-0"> {state.data.message} </p>{" "}
            </div>
          );
        default:
          return "";
      }
    }
    return "";
  };
  return renderMessage();
}
export default FlashMessage;
