import React from 'react';

const FormOne = ({ data, changeHandler, currentStep, handleChangePurpleBtn }) => {
    return(
        <>
        {
        currentStep == 1 ? (<>
            <p className="hp-newagr-content-p">Restrictions:</p>
            { data.restrictions.length > 0 && (data.restrictions.map((el) => {
                return(
                    <div key={el.id}>
                    <div className='d-flex justify-content-between'>
                        <div className='hp-natoggle-content'>
                            <span className="dot">
                                <img src={el.image_url} alt="logout icon " />
                            </span>
                            <p style={{'verticalAlign': 'middle'}} >{el.name}</p>
                        </div>
                        <div className='hp-natoggle-btn' style={{'alignSelf': 'center'}}>
                            <label className="switch mb-0">
                            <input 
                                type="checkbox" 
                                name={el.name} 
                                // value={el.value} 
                                checked={el.value}
                                onChange={(event)=>changeHandler({id:el.id,'formConsent':'resConsent'},event)} 
                            />
                            <span className="hpnatoggle-slider round"></span>
                            </label>
                        </div>
                    </div>
                    <hr />
                    </div>
               
                )
            })
            )
            }
             <div className='d-flex justify-content-between'>
                <div className='hp-natoggle-content'>
                    {/* <span className="dot">
                        <img src={el.image_url} alt="logout icon " />
                    </span> */}
                    <p style={{'verticalAlign': 'middle'}} className="purplebtn-special" >Big Purple Button</p>
                </div>
                <div className='hp-natoggle-btn' style={{'alignSelf': 'center'}}>
                    <label className="switch mb-0">
                    <input 
                        type="checkbox" 
                        name="is_purple_button_enabled" 
                        // value={el.value} 
                        checked={data.is_purple_button_enabled}
                        onChange={handleChangePurpleBtn} 
                    />
                    <span className="hpnatoggle-slider round"></span>
                    </label>
                </div>
            </div>
            <p className="purplebtn-para">
            { 
                data && data.purplebutton.length > 0 && (
                    <p className='hp-postagree-p font-theme-color  mt-4' dangerouslySetInnerHTML={{__html: data.purplebutton[0].text}}  />                                       
                                                    )
                // <p>{Object.keys(data.disagreed_option).length}</p>
                // <p className="hp-postagree-p hp-newagr-specialpoints-beforeowner hp-postagree-list">N/A</p> 
            }
                {/* *Enabling the Big Purple Button allows the Consenter to submit a report if anything goes awry. It is time stamped and dated. */}
            </p>
        
        </>): "" } 
        </>
    );
};


export default FormOne;